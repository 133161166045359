import React, { useEffect, useState } from 'react';
import { Observable, Subscription } from 'rxjs';

import FormTypeFieldsList from '../components/formtypes/fieldlist';
import { Op } from '../components/formtypes/fieldlist';

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdDelete, MdEdit } from 'react-icons/md';

interface IProps {
    gqlClient: IGQLClient,
    siteid: string | null,
    studyid: string,
}

const FormTypes = (props: IProps): JSX.Element => {

    const [formTypes, setFormTypes] = useState<codegenapi.FormType[]>();

    const [editingFormTypeId, setEditingFormTypeId] = useState<string | undefined>();
    const [newFormType, setNewFormType] = useState<codegenapi.FormType | undefined>();

    useEffect(() => {

        async function refreshFormTypes(studyid: string) {
            props.gqlClient.gqlListFormTypes(studyid)
                .then(response => {
                    setFormTypes(response.listFormTypes as codegenapi.FormType[]);
                }).catch(response => {
                    console.error(response.errors);
                });
        }

        let formTypesSubscription: Subscription;

        if (props.studyid) {

            refreshFormTypes(props.studyid);

            props.gqlClient.gqlOnFormTypesUpdatesSubscription(props.studyid)
                .then(subscription => {
                    formTypesSubscription = (subscription as Observable<codegenapi.OnFormTypesUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            refreshFormTypes(props.studyid);
                        },
                        error: error => console.warn(error)
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }

        return () => {
            formTypesSubscription?.unsubscribe();
        }

    }, [props.studyid, props.gqlClient]);

    function addFormType(studyid: string, formType: codegenapi.FormType) {
        props.gqlClient.gqlAddFormType(studyid, formType.identifier, formType.fields as codegenapi.FormTypeFieldInput[])
            .then(_response => {
                cancelNew();
            }).catch(response => {
                console.error(response.errors);
            });
    }

    function updateFormType(studyid: string, formtypeid: string, formType: codegenapi.FormType) {
        props.gqlClient.gqlUpdateFormType(studyid, formtypeid, formType.identifier, formType.fields)
            .then(_response => {
                cancelEdit();
            }).catch(response => {
                console.error(response.errors);
            });
    }

    function cancelNew() {
        setNewFormType(undefined);
    }

    function cancelEdit() {
        setEditingFormTypeId(undefined);
    }

    return (
        <Flex className='flex-row'>
            <Flex>
                <h1>Form types</h1>
                <Loader variation="linear" style={{ display: formTypes && 'none' }} />
                {
                    formTypes &&
                    <Flex>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">&nbsp;</TableCell>
                                    <TableCell as="th">Fields</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    formTypes && formTypes.length > 0 && formTypes.map((formType) => (
                                        <TableRow
                                            key={formType.formtypeid}
                                            style={{
                                                backgroundColor: formType.formtypeid === editingFormTypeId ? 'lightcyan' : 'white'
                                            }}
                                        >
                                            <TableCell>{formType.identifier}</TableCell>
                                            <TableCell>
                                                {formType.fields ? formType.fields.length : 0}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => {
                                                    setEditingFormTypeId(formType.formtypeid);
                                                }}>
                                                    <MdEdit />
                                                </Button>
                                                <Button onClick={() => {
                                                    props.gqlClient.gqlRemoveFormType(props.studyid, formType.formtypeid);
                                                }}>
                                                    <MdDelete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    (!formTypes || formTypes.length === 0) &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={3}><i>No form types yet!</i></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Flex>
                }
                <Button variation="primary"
                    onClick={() => setNewFormType({} as codegenapi.FormType)}
                >
                    New form type
                </Button>
            </Flex>

            {
                editingFormTypeId &&
                <FormTypeFieldsList
                    cancelEdit={cancelEdit}
                    gqlClient={props.gqlClient}
                    editingFormTypeId={editingFormTypeId}
                    formTypes={formTypes ?? []}
                    op={Op.UPDATE}
                    siteid={props.siteid}
                    studyid={props.studyid}
                    addFormType={null}
                    updateFormType={updateFormType}
                />
            }

            {
                newFormType &&
                <FormTypeFieldsList
                    cancelEdit={cancelNew}
                    gqlClient={props.gqlClient}
                    editingFormTypeId={null}
                    formTypes={formTypes ?? []}
                    op={Op.ADD}
                    siteid={props.siteid}
                    studyid={props.studyid}
                    addFormType={addFormType}
                    updateFormType={null}
                />
            }
        </Flex>
    )
}

export default FormTypes;
