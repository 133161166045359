import { Flex } from '@aws-amplify/ui-react';
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom'
import { IGQLClient } from '../../client/gqlts';

import ActivityTypes from '../../pages/ActivityTypes';
import EditStudy from './edit';
import FormTypes from '../../pages/FormTypes';
import Subjects from '../../pages/Subjects';

import * as codegenapi from '../../graphql/API';

interface IProps {
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string,
    studies: codegenapi.Study[] | undefined,
    user: any,
}

const Study = (props: IProps): JSX.Element => {

    const { studyid } = useParams();

    const study = props.studies?.find((study) => study.studyid === studyid)

    return (
        <Flex>
            {
                studyid &&
                <>
                    <h1>Study: {study?.name}</h1>
                    <Routes>
                        <Route path="*" element={
                            <Flex>
                                <Flex className="flex-row">
                                    <Subjects
                                        gqlClient={props.gqlClient}
                                        setErrors={props.setErrors}
                                        siteid={props.siteid}
                                        study={study}
                                        user={props.user}
                                    />
                                </Flex>
                                {/* <Flex className="flex-row"> */}
                                {/*     <ActivityTypes */}
                                {/*         gqlClient={props.gqlClient} */}
                                {/*         siteid={props.siteid} */}
                                {/*         studyid={studyid} */}
                                {/*     /> */}
                                {/* </Flex> */}
                                {/* <Flex className="flex-row"> */}
                                {/*     <FormTypes */}
                                {/*         gqlClient={props.gqlClient} */}
                                {/*         siteid={props.siteid} */}
                                {/*         studyid={studyid} */}
                                {/*     /> */}
                                {/* </Flex> */}
                            </Flex>
                        } />
                        <Route path="edit" element={<EditStudy />} />
                        <Route path="activitytypes/*" element={
                            <Flex className="flex-row">
                                <ActivityTypes
                                    gqlClient={props.gqlClient}
                                    siteid={props.siteid}
                                    studyid={studyid}
                                />
                            </Flex>
                        } />
                        <Route path="formtypes/*" element={
                            <Flex className="flex-row">
                                <FormTypes
                                    gqlClient={props.gqlClient}
                                    siteid={props.siteid}
                                    studyid={studyid}
                                />
                            </Flex>
                        } />
                        <Route path="subjects/*" element={
                            <Flex className="flex-row">
                                <Subjects
                                    gqlClient={props.gqlClient}
                                    setErrors={props.setErrors}
                                    siteid={props.siteid}
                                    study={study}
                                    user={props.user}
                                />
                            </Flex>
                        } />
                    </Routes>
                </>

            }
        </Flex>
    )
}

export default Study;
