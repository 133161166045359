import React from 'react';
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdDelete } from 'react-icons/md';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

interface IProps {
    activitytypeid: string,
    cancelRefData: () => void,
    gqlClient: IGQLClient
    referenceData: codegenapi.ReferenceData[] | undefined,
    setNewReferenceData: () => void,
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const ReferenceDataList = (props: IProps): JSX.Element => {

    return (
        <Flex className="flex-row">
            <Flex>
                <h1>Reference data</h1>
                <Loader variation="linear" style={{ display: props.referenceData && 'none' }} />
                {
                    props.referenceData &&
                    <Flex>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">File</TableCell>
                                    <TableCell as="th">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.referenceData && props.referenceData.length > 0 && props.referenceData.map((referenceDataInstance) => (
                                        <TableRow
                                            style={{ fontFamily: 'monospace' }}
                                            key={referenceDataInstance.referencedataid}
                                        >
                                            <TableCell>
                                                {referenceDataInstance.filename}
                                            </TableCell>
                                            <TableCell>{referenceDataInstance.status}</TableCell>
                                            <TableCell>
                                                <Button
                                                    disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                                >
                                                    <MdDelete
                                                        onClick={
                                                            () => (props.gqlClient.gqlRemoveReferenceData(referenceDataInstance.subjectid, referenceDataInstance.referencedataid))
                                                        }
                                                    />

                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    (!props.referenceData || props.referenceData.length === 0) &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={3}><i>No reference data yet!</i></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <Flex className="flex-row">
                            <Button
                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                onClick={() => props.setNewReferenceData()}
                                style={{ flexGrow: 3 }}
                                variation="primary"
                            >
                                Add reference data
                            </Button>
                            <Button
                                onClick={() => props.cancelRefData()}
                                style={{ flexGrow: 1 }}
                                variation="primary"
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Flex>
                }
            </Flex>


        </Flex>
    )
}

export default ReferenceDataList;
