import React, { ChangeEvent, useState } from 'react';
import { Button, Card, Flex, TextField } from '@aws-amplify/ui-react';

import { IGQLClient } from '../../client/gqlts';

interface IProps {
    cancelAdd: () => void,
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string | null,
}

const NewStudy = (props: IProps): JSX.Element => {

    const [studyName, setStudyName] = useState('');

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setStudyName(e.target.value);
        e.preventDefault()
    }

    function addStudy() {
        if (props.siteid && studyName)
            props.gqlClient.gqlAddStudy(props.siteid, studyName)
                .then(_response => props.cancelAdd())
                .catch(response => {
                    props.setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors)
                });
    }

    return (
        <Flex>
            <h1>New study</h1>
            <Card>
                <TextField
                    label="Name"
                    onChange={handleChange}
                    value={studyName}
                />
            </Card>
            <Flex className="flex-row">
                <Button
                    onClick={() => addStudy()}
                    style={{ flexGrow: 3 }}
                    variation="primary"
                >
                    Add study
                </Button>
                <Button
                    onClick={() => props.cancelAdd()}
                    style={{ flexGrow: 1 }}
                    variation="primary"
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    )
}

export default NewStudy;
