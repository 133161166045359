import React, { useEffect, useState } from 'react';

import FormFieldList from './fieldlist';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';
import { Flex } from '@aws-amplify/ui-react';

interface IProps {
    cancelEdit: () => void,
    editingFormTypeId: string,
    forms: codegenapi.Form[],
    gqlClient: IGQLClient,
    singleFormType: codegenapi.FormType | undefined,
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const EditForm = (props: IProps): JSX.Element => {

    const [fields, setFields] = useState<codegenapi.KeyValuePair[]>([]);
    const [formtype, setFormtype] = useState<codegenapi.FormType>();

    useEffect(() => {

        if (props.subject?.subjectid && props.editingFormTypeId) {
            const form = props.forms.find(form => form.formtypeid === props.editingFormTypeId);
            setFields(form?.fields as codegenapi.KeyValuePair[]);
            setFormtype(form?.formtype as codegenapi.FormType);
        }

    }, [props]);

    function updateForm(fields: codegenapi.KeyValuePair[]) {
        if (props.subject?.subjectid && props.editingFormTypeId)
            props.gqlClient.gqlUpdateForm(
                props.subject?.subjectid,
                props.editingFormTypeId,
                fields,
            )
                .then(_response => {
                    props.cancelEdit();
                }).catch(response => {
                    console.error(response.errors);
                });
    }

    return (
        <Flex>
            <h1>{formtype?.identifier}</h1>
            {
                fields && formtype &&
                <FormFieldList
                    cancel={props.singleFormType ? undefined : props.cancelEdit}
                    fields={fields}
                    formtype={formtype}
                    setFields={setFields}
                    subject={props.subject}
                    updateForm={updateForm}
                />
            }
        </Flex>
    )
}

export default EditForm;
