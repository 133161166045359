import React, { ChangeEvent, useState } from "react";
import { Button, Card, Flex, TextField } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";

import { IGQLClient } from "../../client/gqlts";
import * as codegenapi from "../../graphql/API";

interface IProps {
  cancelEdit: () => void;
  gqlClient: IGQLClient;
  siteid: string | null;
  subject: codegenapi.Subject;
}

const EditSubject = (props: IProps): JSX.Element => {
  const [subjectid, setSubjectid] = useState<string>(props.subject.subjectid);

  const { studyid } = useParams();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setSubjectid(e.target.value);
    e.preventDefault();
  }

  function updateSubject() {
    if (props.siteid && studyid && subjectid)
      props.gqlClient
        .gqlUpdateSubject(
          props.siteid,
          studyid,
          props.subject.subjectid,
          props.subject.identifier,
          props.subject.devices
        )
        .then((_response) => props.cancelEdit())
        .catch((response) => console.error(response.errors));
  }

  return (
    <Flex>
      <h1>Edit subject</h1>
      <Card>
        <TextField
          label="Identifier"
          onChange={handleChange}
          value={subjectid}
        />
      </Card>
      <Flex className="flex-row">
        <Button
          onClick={() => updateSubject()}
          style={{ flexGrow: 3 }}
          variation="primary"
        >
          Update
        </Button>
        <Button
          onClick={() => props.cancelEdit()}
          style={{ flexGrow: 1 }}
          variation="primary"
        >
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditSubject;
