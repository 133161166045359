import React, { useEffect, useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';
import { useNavigate } from 'react-router-dom';

import { Logo } from '../components/layout';

interface IProps {
    auth: (typeof Auth),
    user: AmplifyUser | null | undefined
}

const SignIn = (props: IProps): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(props.user === undefined);
        if (props.user)
            navigate('/');
    }, [props.user, navigate]);

    return (
        <>
            <div className="App">
                <header className="App-header">
                </header>
                <div className='App-intro'>
                    <Logo />
                    {
                        loading &&
                        <p>Loading...</p>
                    }
                    {
                        !loading &&
                        <>
                            <p>
                                Hello guest!
                            </p>
                            <Button
                                variation="primary"
                                onClick={
                                    () => {
                                        setLoading(true);
                                        props.auth.federatedSignIn({ customProvider: 'helseid' })
                                            .then(() => setLoading(true));
                                    }
                                }
                            >
                                Sign In
                            </Button>
                        </>
                    }
                </div>
            </div>
        </>
    );

}

export default SignIn;
