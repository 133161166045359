import { Observable, Subscription } from 'rxjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom'
import { Button, Flex } from '@aws-amplify/ui-react';

import NewSubject from '../components/subjects/new';
import Subject from '../components/subjects/subject'
import SubjectsList from '../components/subjects/list'

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';
import EditSubject from '../components/subjects/edit';

interface IProps {
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string,
    study: codegenapi.Study | undefined,
    user: any,
}

const Subjects = (props: IProps): JSX.Element => {

    const [subjects, setSubjects] = useState<codegenapi.Subject[]>();

    const [newSubject, setNewSubject] = useState<codegenapi.Subject>();
    const [editingSubject, setEditingSubject] = useState<codegenapi.Subject>();

    const { studyid, subjectid } = useParams();

    const { setErrors } = props;

    const refreshSubjects = useCallback((studyid: string) => {
        props.gqlClient.gqlListSubjects(studyid)
            .then(response => {
                setSubjects(response.listSubjects as codegenapi.Subject[]);
            }).catch(response => {
                setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors);
            });
    }, [props.gqlClient, setErrors]);

    useEffect(() => {

        let subjectsSubscription: Subscription;

        if (studyid) {
            refreshSubjects(studyid);
            props.gqlClient.gqlOnSubjectsUpdatesSubscription(studyid)
                .then(subscription => {
                    subjectsSubscription = (subscription as Observable<codegenapi.OnSubjectsUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            refreshSubjects(studyid);
                        },
                        error: response => setErrors(prevState => prevState ? prevState.concat(response.error) : [response.error])
                    })
                })
                .catch(response => {
                    setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors);
                });
        }

        return () => {
            subjectsSubscription?.unsubscribe();
        }

    }, [props.gqlClient, refreshSubjects, studyid, setErrors]);

    useEffect(() => {

        let subjectSubscription: Subscription;

        if (studyid && subjectid) {
            props.gqlClient.gqlOnSubjectUpdatesSubscription(studyid, subjectid)
                .then(subscription => {
                    subjectSubscription = (subscription as Observable<codegenapi.OnSubjectUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            refreshSubjects(studyid);
                        },
                        error: response => setErrors(prevState => prevState ? prevState.concat(response.error) : [response.error])
                    })
                })
                .catch(response => {
                    setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors);
                });
        }

        return () => {
            subjectSubscription?.unsubscribe();
        }

    }, [props.gqlClient, refreshSubjects, studyid, subjectid, setErrors]);

    return (
        <Flex>
            <Routes>
                <Route path="" element={
                    <Flex className="flex-row">
                        <Flex>
                            <SubjectsList
                                gqlClient={props.gqlClient}
                                setEditingSubject={setEditingSubject}
                                setErrors={props.setErrors}
                                siteid={props.siteid}
                                subjects={subjects}
                                user={props.user}
                            />
                            <Flex>
                                <Button
                                    variation="primary"
                                    onClick={() => setNewSubject({} as codegenapi.Subject)}
                                >
                                    New subject
                                </Button>
                            </Flex>
                        </Flex>
                        {
                            newSubject &&
                            <Flex>
                                <NewSubject
                                    gqlClient={props.gqlClient}
                                    cancelAdd={() => setNewSubject(undefined)}
                                    setErrors={props.setErrors}
                                    siteid={props.siteid}
                                />
                            </Flex>
                        }
                        {
                            editingSubject &&
                            <Flex>
                                <EditSubject
                                    cancelEdit={() => setEditingSubject(undefined)}
                                    gqlClient={props.gqlClient}
                                    siteid={props.siteid}
                                    subject={editingSubject}
                                />
                            </Flex>
                        }
                    </Flex>
                } />
                <Route path=":subjectid/*" element={
                    <Subject
                        gqlClient={props.gqlClient}
                        setErrors={props.setErrors}
                        siteid={props.siteid}
                        subjects={subjects}
                        study={props.study}
                    />
                } />
            </Routes>
        </Flex>
    )
}

export default Subjects;
