import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Button, Card, Flex, Loader, TextField } from '@aws-amplify/ui-react';
import { Outlet, useParams } from 'react-router-dom'

function EditStudy(props) {

    const [study, setStudy] = useState(null);

    const { studyid } = useParams();

    useEffect(event => {

        if (event)
            console.log(event);

        refreshStudy(props.siteid, studyid);

    }, [props.siteid, studyid]);

    function refreshStudy(siteid,studyid) {
        setStudy(null);

        API.graphql({
            query: 'query GetStudy($siteid:ID!,$studyid:ID!){ getStudy(siteid:$siteid,studyid:$studyid) { siteid, name, status, subjects { subjectid } } }',
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                siteid: siteid,
                studyid: studyid,
            }
        }).then(response => {
            setStudy(response.data.getStudy);
        }).catch(response => {
            console.error(response.errors);
        });

    }
    function handleNameChange(e) {
        setStudy((prevStudy) => ({ ...prevStudy, name: e.target.value }));
        e.preventDefault()
    }
    function handleStatusChange(e) {
        setStudy((prevStudy) => ({ ...prevStudy, status: e.target.value }));
        e.preventDefault()
    }

    return (
        <Flex>
            <h1>Edit study</h1>
            <Loader variation="linear" style={{ display: study && 'none' }} />
            {
                study &&
                <Flex>
                    <Card>
                        <TextField label="Name" value={study.name} onChange={handleNameChange} />
                        <TextField label="Status" value={study.status} onChange={handleStatusChange} />
                    </Card>
                    <Flex>
                        <Button variation="primary">Update</Button>
                    </Flex>
                    <Flex>
                        <Outlet />
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

export default EditStudy;
