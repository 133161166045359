import { Button, SelectField, TableCell, TableRow, Text, TextField } from '@aws-amplify/ui-react';
import { MdCancel, MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

export enum EnumOp {
    new,
    edit,
    show
}

interface IProps {
    activity: codegenapi.Activity,
    activityTypes: codegenapi.ActivityType[],
    cancelEditing: () => any,
    date: number,
    deleteActivity: ((activitytypeid: string) => any) | undefined,
    editingActivityType: string | undefined,
    editingReferenceDataActivityType: string | undefined,
    gqlClient: IGQLClient
    op: EnumOp,
    setActivity: React.Dispatch<React.SetStateAction<codegenapi.Activity | undefined>>;
    setEditingActivityType: React.Dispatch<React.SetStateAction<string | undefined>>;
    setEditingRefDataActivity: React.Dispatch<React.SetStateAction<codegenapi.Activity | undefined>>;
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const ActivityRow = (props: IProps): JSX.Element => {

    const [readonly, setReadonly] = useState<boolean>(props.op === EnumOp.show);
    const [localActivity, setLocalActivity] = useState<codegenapi.Activity>(props.activity);

    const { setActivity } = props;

    //  must handle startTime and stopTime separately due to the epoch/UTC conversions
    const [startTime, setStartTime] = useState<string>('00:00');
    const [stopTime, setStopTime] = useState<string>('00:00');

    //  define conversion as callback for reuse, extract to separate file for wider reuse?
    const localtimeToEpochUTC = useCallback((localTime: string): number => {

        //  calculate utc epoch at midnight of the forms date
        const utcDate = new Date(props.date * 1000);
        const epochUtcMidnight = Date.UTC(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());

        //  calculate utc epoch offset of local time
        const localTimeEpochOffset = new Date(0);  //  0 epoch
        localTimeEpochOffset.setHours(Number.parseInt(localTime.split(':')[0]));
        localTimeEpochOffset.setMinutes(Number.parseInt(localTime.split(':')[1]));
        localTimeEpochOffset.setSeconds(0);
        localTimeEpochOffset.setMilliseconds(0);

        //  deal with summer time
        const offsetMinutes = localTimeEpochOffset.getTimezoneOffset();

        //  return sum (epoch in seconds)
        return epochUtcMidnight / 1000 + localTimeEpochOffset.valueOf() / 1000 + offsetMinutes * 60;

    }, [props.date]);

    //  conversion function, might extract to own file later?
    function epochUTCToLocaltime(epoch: number): string {
        const date = new Date(epoch * 1000);
        return date.toLocaleTimeString("no").split(':').slice(0, -1).join(':'); //  BACK-122
    }

    //  handle changes to the input fields
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.name === 'startTime')
            setStartTime(e.target.value);
        else if (e.target.name === 'stopTime')
            setStopTime(e.target.value);
        else
            setActivity(
                prevState => {
                    if (prevState) {
                        return { ...prevState, [e.target.name]: e.target.value }
                    }
                    if (!prevState) return { ...localActivity, [e.target.name]: e.target.value }
                });
    }

    //  handle changes to the act. type selection
    function handleActivityTypeChange(e: ChangeEvent<HTMLSelectElement>) {
        setActivity(
            prevState => {
                if (prevState) return { ...prevState, [e.target.name]: e.target.value }
                if (!prevState) return { ...localActivity, [e.target.name]: e.target.value }
            });
    }

    //  update parent state with conversion of startTime
    useEffect(() => {
        if (startTime)
            setActivity(
                prevState => {
                    if (prevState)
                        return { ...prevState, "startTime": localtimeToEpochUTC(startTime) }
                }
            );
    }, [startTime, setActivity, localtimeToEpochUTC]);

    //  update parent state with conversion of stopTime
    useEffect(() => {
        if (stopTime)
            setActivity(
                prevState => {
                    if (prevState)
                        return { ...prevState, "stopTime": localtimeToEpochUTC(stopTime) }
                }
            );
    }, [stopTime, setActivity, localtimeToEpochUTC]);

    //  set read-only flag based on activitytypeid
    useEffect(() => {
        setReadonly(
            !(props.op === EnumOp.new)  //  new
            && !(props.editingActivityType === localActivity.activitytypeid)    //  edit
        );
    }, [props.editingActivityType, localActivity.activitytypeid, props.op]);

    //  keep localActivity state in sync with parent
    useEffect(() => {
        setLocalActivity(props.activity);
    }, [props.activity]);

    //  initialize local startTime/stopTime state with localActivity
    useEffect(() => {
        setStartTime(epochUTCToLocaltime(localActivity.startTime));
        setStopTime(epochUTCToLocaltime(localActivity.stopTime));
    }, [localActivity]);


    return (
        <TableRow
            style={{ backgroundColor: props.editingReferenceDataActivityType && props.editingReferenceDataActivityType === props.activity?.activitytypeid ? 'lightcyan' : 'white' }}
        >
            {
                readonly &&
                <>
                    <TableCell>
                        <Text
                            fontWeight='bold'
                        >
                            {props.activity.activitytype?.description}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text>{epochUTCToLocaltime(props.activity?.startTime)}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{epochUTCToLocaltime(props.activity?.stopTime)}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{props.activity?.comment}</Text>
                    </TableCell>
                    <TableCell>
                        <Button
                            onClick={() => props.setEditingRefDataActivity(props.activity)}
                        >
                            {props.activity?.referenceData ? props.activity?.referenceData.length : 0}
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                            onClick={() => props.setEditingActivityType(props.activity.activitytype?.activitytypeid)}
                        >
                            <MdEdit />
                        </Button>
                        <Button
                            disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                            onClick={() => props.deleteActivity && props.deleteActivity(props.activity.activitytypeid)}
                        >
                            <MdDelete />
                        </Button>
                    </TableCell>
                </>
            }
            {
                !readonly &&
                <>
                    <TableCell>
                        <SelectField
                            name='activitytypeid'
                            defaultValue={localActivity.activitytype?.activitytypeid}
                            label=''
                            onChange={handleActivityTypeChange}
                            placeholder='Select activity...'
                            // variation='quiet'
                            disabled={props.op !== EnumOp.new}
                            labelHidden
                            style={{ width: '12rem' }}>
                            {
                                props.activityTypes &&
                                props.activityTypes.length > 0 &&
                                props.activityTypes.sort(
                                    (a, b) => a.description.localeCompare(b.description)
                                ).map((activityType) => (
                                    <option
                                        key={activityType.activitytypeid}
                                        value={activityType.activitytypeid}
                                    >
                                        {activityType.description}
                                    </option>
                                ))
                            }
                        </SelectField>
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='startTime'
                            value={startTime}
                            label=''
                            onChange={handleChange}
                            // type='time'  //  BACK-122
                            pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                            variation='quiet'
                            required
                            disabled={props.op === EnumOp.edit}
                            labelHidden
                            style={{ width: '5rem' }} />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='stopTime'
                            value={stopTime}
                            label=''
                            onChange={handleChange}
                            // type='time'  //  BACK-122
                            pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                            variation='quiet'
                            required
                            disabled={props.op === EnumOp.edit}
                            labelHidden
                            style={{ width: '5rem' }} />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='comment'
                            defaultValue={localActivity.comment ?? ''}
                            label=''
                            onChange={handleChange}
                            variation='quiet'
                            disabled={props.op === EnumOp.edit}
                            labelHidden
                            style={{ width: '12rem' }} />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='referenceData'
                            defaultValue={localActivity.referenceData ? localActivity.referenceData.length : 'none'}
                            label=''
                            onChange={handleChange}
                            variation='quiet'
                            disabled
                            labelHidden
                            style={{ width: '5rem' }} />
                    </TableCell>
                    <TableCell>
                        <Button type="submit">
                            <MdCheck />
                        </Button>
                        <Button onClick={() => {
                            props.cancelEditing();
                        }}>
                            <MdCancel />
                        </Button>
                    </TableCell>
                </>
            }
        </TableRow>
    )
}

export default ActivityRow;
