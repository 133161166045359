import { Observable, Subscription } from 'rxjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom'
import { Alert, Button, Flex } from '@aws-amplify/ui-react';

import DevicesList from '../components/devices/list';
import NewDevice from '../components/devices/new';
import EditDevice from '../components/devices/edit';

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';

interface IProps {
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string | null,
}

const Devices = (props: IProps): JSX.Element => {

    const [devices, setDevices] = useState<codegenapi.Device[]>();

    const [newDevice, setNewDevice] = useState<codegenapi.Device>();
    const [editingDevice, setEditingDevice] = useState<codegenapi.Device>();

    const { setErrors } = props;

    const refreshDevices = useCallback((siteid: string) => {
        props.gqlClient.gqlListDevices(siteid)
            .then(response => {
                setDevices(response.listDevices as codegenapi.Device[]);
            }).catch(response => {
                setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors);
            });
    }, [props.gqlClient, setErrors]);

    useEffect(() => {

        let devicesSubscription: Subscription;

        if (props.siteid) {
            refreshDevices(props.siteid);
            props.gqlClient.gqlOnDevicesUpdatesSubscription(props.siteid)
                .then(subscription => {
                    devicesSubscription = (subscription as Observable<codegenapi.OnDevicesUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            if (props.siteid)
                                refreshDevices(props.siteid);
                        },
                        error: response => setErrors(prevState => prevState ? prevState.concat(response.error) : [response.error])
                    })
                })
                .catch(response => {
                    setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors);
                });
        }

        return () => {
            devicesSubscription?.unsubscribe();
        }

    }, [props.gqlClient, refreshDevices, props.siteid, setErrors]);

    return (
        <Flex>
            <Routes>
                <Route path="" element={
                    <Flex className="flex-row">
                        {
                            !props.siteid &&
                            <Alert
                                heading="Site not selected"
                                variation="info"
                            >
                                Please select site in the menu above
                            </Alert>
                        }
                        {
                            props.siteid &&
                            <Flex>
                                <DevicesList
                                    gqlClient={props.gqlClient}
                                    setEditingDevice={setEditingDevice}
                                    siteid={props.siteid}
                                    devices={devices}
                                />
                                <Flex>
                                    <Button
                                        variation="primary"
                                        onClick={() => setNewDevice({} as codegenapi.Device)}
                                    >
                                        New device
                                    </Button>
                                </Flex>
                            </Flex>
                        }
                        {
                            props.siteid && newDevice &&
                            <Flex>
                                <NewDevice
                                    cancelAdd={() => setNewDevice(undefined)}
                                    gqlClient={props.gqlClient}
                                    setErrors={props.setErrors}
                                    siteid={props.siteid}
                                />
                            </Flex>
                        }
                        {
                            props.siteid && editingDevice &&
                            <Flex>
                                <EditDevice
                                    cancelEdit={() => setEditingDevice(undefined)}
                                    device={editingDevice}
                                    gqlClient={props.gqlClient}
                                    setErrors={props.setErrors}
                                    siteid={props.siteid}
                                />
                            </Flex>
                        }
                    </Flex>
                } />
                <Route
                    path="new"
                    element={
                        props.siteid &&
                        <Flex className="flex-page">
                            <DevicesList
                                gqlClient={props.gqlClient}
                                setEditingDevice={setEditingDevice}
                                siteid={props.siteid}
                                devices={devices}
                            />
                        </Flex>
                    }
                />
                {/* <Route */}
                {/*     path=":subjectid/*" */}
                {/*     element={ */}
                {/*         <Device */}
                {/*             gqlClient={props.gqlClient} */}
                {/*             setErrors={props.setErrors} */}
                {/*             siteid={props.siteid} */}
                {/*             devices={devices} */}
                {/*         /> */}
                {/*     } */}
                {/* /> */}
            </Routes>
        </Flex >
    )
}

export default Devices;
