import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Flex, SelectField, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdDelete } from 'react-icons/md';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

interface IProps {
    addSubjectDevice: (subjectDevice: codegenapi.SubjectDevice) => Promise<void>,
    devices: codegenapi.SubjectDevice[] | undefined,
    gqlClient: IGQLClient,
    removeSubjectDevice: (subjectDevice: codegenapi.SubjectDevice) => Promise<void>,
    siteid: string | null,
    subject: codegenapi.Subject,
}

const SubjectDevices = (props: IProps): JSX.Element => {

    const [newSubjectDevice, setNewSubjectDevice] = useState<codegenapi.SubjectDevice>();
    const [devices, setDevices] = useState<codegenapi.Device[]>();

    function handleDeviceIdChange(e: ChangeEvent<HTMLSelectElement>) {
        setNewSubjectDevice(prevState => {
            if (prevState)
                return { ...prevState, deviceid: e.target.value } as codegenapi.SubjectDevice
            else
                return { deviceid: e.target.value } as codegenapi.SubjectDevice
        });
        e.preventDefault();
    }

    function handleDevicePositionChange(e: ChangeEvent<HTMLSelectElement>) {
        setNewSubjectDevice(prevState => {
            if (prevState)
                return { ...prevState, position: e.target.value } as codegenapi.SubjectDevice
            else
                return { position: e.target.value } as codegenapi.SubjectDevice
        });
        e.preventDefault();
    }

    useEffect(() => {
        if (props.siteid)
            props.gqlClient.gqlListDevices(props.siteid)
                .then(response => setDevices(response.listDevices as codegenapi.Device[]))
                .catch(response => console.error(response.errors));
    }, [props.gqlClient, props.siteid]);

    return (
        <Flex className="flex-row">
            <Flex>
                <h1>Devices</h1>
                <Flex>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Device ID</TableCell>
                                <TableCell as="th">Position</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.devices &&
                                props.devices.map((device) => (
                                    <TableRow key={device.deviceid}>
                                        <TableCell>{device.deviceid}</TableCell>
                                        <TableCell>{device.position}</TableCell>
                                        <TableCell>
                                            <Button
                                                disabled={props.subject.status === codegenapi.SubjectStatusType.CLOSED}
                                                onClick={() => props.removeSubjectDevice(device)}
                                            >
                                                <MdDelete />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            {
                                (!props.devices || props.devices.length === 0) &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={2}><i>No devices yet!</i></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Flex>
                        <Button
                            disabled={props.subject.status === codegenapi.SubjectStatusType.CLOSED}
                            onClick={() => setNewSubjectDevice({} as codegenapi.SubjectDevice)}
                            variation="primary"
                        >
                            Add device
                        </Button>
                    </Flex>
                </Flex>
            </Flex>

            {
                newSubjectDevice &&
                <Flex>
                    <h1>Add device</h1>
                    <Card>
                        <SelectField
                            label="Location"
                            onChange={handleDevicePositionChange}
                            placeholder="Select location..."
                            required
                        >
                            <option value="ARM">arm</option>
                            <option value="CHEST">chest</option>
                        </SelectField>
                        <SelectField
                            label="Device ID"
                            onChange={handleDeviceIdChange}
                            required
                            value={newSubjectDevice?.deviceid ?? ''}
                            placeholder="Select device..."
                        >
                            {
                                devices && devices.map(device => (
                                    <option key={device.deviceid} value={device.deviceid}>
                                        {device.deviceid}
                                    </option>
                                ))
                            }
                        </SelectField>

                    </Card>
                    <Flex className="flex-row">
                        <Button
                            onClick={
                                () => {
                                    props.addSubjectDevice(newSubjectDevice)
                                        .then(_response => setNewSubjectDevice(undefined))
                                        .catch(response => console.error(response.errors))
                                }
                            }
                            style={{ flexGrow: 3 }}
                            variation="primary"
                        >
                            Add
                        </Button>
                        <Button
                            onClick={() => setNewSubjectDevice(undefined)}
                            style={{ flexGrow: 1 }}
                            variation="primary"
                        >
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            }

        </Flex>
    );
}

export default SubjectDevices
