import { Observable, Subscription } from 'rxjs';
import React, { useEffect, useState } from 'react';
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdEdit, MdDelete } from 'react-icons/md';
import { Outlet, useNavigate } from 'react-router-dom'

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';

interface IProps {
    user: any,
    siteid: string | null,
    setSiteId: React.Dispatch<React.SetStateAction<string | null>>;
    gqlClient: IGQLClient
}

const Sites = (props: IProps): JSX.Element => {

    const navigate = useNavigate();

    const { siteid, setSiteId } = props;
    const [sites, setSites] = useState<codegenapi.Site[]>();

    useEffect(() => {

        async function refreshSites() {
            props.gqlClient.gqlListSites()
                .then(response => {
                    setSites(response.listSites as codegenapi.Site[]);
                })
                .catch(response => {
                    console.error(response.errors);
                })
        }

        refreshSites();

        let sitesSubscription: Subscription;
        props.gqlClient.gqlOnSitesUpdatesSubscription()
            .then(subscription => {
                sitesSubscription = (subscription as Observable<codegenapi.OnSitesUpdatesSubscription>).subscribe({
                    next: () => {
                        //  The data returned by the subscrition isn't complete
                        refreshSites();
                    },
                    error: error => console.warn(error)
                })
            })
            .catch(error => {
                console.error(error);
            });

        return () => {
            sitesSubscription?.unsubscribe();
        }

    }, [props.gqlClient]);

    return (
        <Flex className="flex-page">
            <Flex>
                <h1>Sites</h1>
                <Loader variation="linear" style={{ display: sites && 'none' }} />
                {
                    sites &&
                    <Flex>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">&nbsp;</TableCell>
                                    <TableCell as="th">Studies</TableCell>
                                    <TableCell as="th">Devices</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sites.length > 0 && sites.map((site) => (
                                        <TableRow key={site.siteid}>
                                            <TableCell>
                                                <Button
                                                    variation={site.siteid === siteid ? "primary" : "menu"}
                                                    onClick={() => setSiteId(site.siteid)}>
                                                    {site.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={
                                                        () => {
                                                            setSiteId(site.siteid);
                                                            navigate('/studies')
                                                        }
                                                    }>
                                                    {site.studies?.length}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={
                                                        () => {
                                                            setSiteId(site.siteid);
                                                            navigate(`/devices`)
                                                        }
                                                    }>
                                                    {site.devices?.length}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={
                                                        () => {
                                                            setSiteId(site.siteid);
                                                            navigate(`./${site.siteid}/edit`)
                                                        }
                                                    }
                                                >
                                                    <MdEdit />
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        props.gqlClient.gqlRemoveSite(site.siteid)
                                                        if (site.siteid === siteid)
                                                            setSiteId(null);
                                                    }}
                                                >
                                                    <MdDelete />
                                                </Button>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    sites.length === 0 &&
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                        >
                                            <i>No sites yet!</i>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <Flex>
                            <Button variation="primary"
                                onClick={() => navigate('/sites/new')}
                            >
                                New site
                            </Button>
                        </Flex>
                    </Flex>
                }
            </Flex>
            <Outlet />
        </Flex>
    );
}

export default Sites
