import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Card, Flex, TextField } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom'

import { IGQLClient } from '../../client/gqlts';

interface IProps {
    user: any,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string | null,
    gqlClient: IGQLClient
}

const NewSite = (props: IProps): JSX.Element => {

    const navigate = useNavigate();

    const [sitename, setSitename] = useState<string>('');
    const [identifier, setIdentifier] = useState<string>('');

    function addSite() {
        if (identifier && sitename)
            props.gqlClient.gqlAddSite(identifier, sitename)
                .then(_response => {
                    navigate('..');
                })
                .catch(response => {
                    props.setErrors(prevState => prevState ? prevState?.concat(response.errors) : response.errors);
                });
    }

    function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setSitename(e.target.value);
    }

    function handleIdentifierChange(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setIdentifier(e.target.value);
    }

    function postValidation(e: FormEvent<HTMLDivElement>) {
        e.preventDefault();
        addSite();
    }

    return (
        <Flex as="form" onSubmit={postValidation}>
            <h1>New site</h1>
            <Card>
                <TextField
                    label="Site name"
                    onChange={handleNameChange}
                    required
                    value={sitename}
                />
                <TextField
                    label="Identifier"
                    onChange={handleIdentifierChange}
                    pattern="[a-z]+"
                    placeholder="[a-z]+"
                    required
                    value={identifier}
                />
            </Card>
            <Button
                type="submit"
                variation="primary"
            >
                Create
            </Button>
        </Flex >
    )
}

export default NewSite;
