import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Card, Flex, TextField } from '@aws-amplify/ui-react';

import { IGQLClient } from '../../client/gqlts';

interface IProps {
    cancelAdd: (() => void),
    gqlClient: IGQLClient
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string,
}

const NewDevice = (props: IProps): JSX.Element => {

    const [deviceid, setDeviceid] = useState<string>('');

    function addDevice() {
        if (deviceid)
            props.gqlClient.gqlAddDevice(props.siteid, deviceid)
                .then(props.cancelAdd)
                .catch(response => {
                    props.setErrors(prevState => prevState ? prevState?.concat(response.errors) : response.errors);
                });
    }

    function handleIdentifierChange(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setDeviceid(e.target.value);
    }

    function postValidation(e: FormEvent<HTMLDivElement>) {
        e.preventDefault();
        addDevice();
    }

    return (
        <Flex as="form" onSubmit={postValidation}>
            <h1>New device</h1>
            <Card>
                <TextField
                    label="DeviceID"
                    onChange={handleIdentifierChange}
                    pattern="[A-Z0-9]+"
                    placeholder="[A-Z0-9]+"
                    required
                    value={deviceid}
                />
            </Card>
            <Flex className="flex-row">
                <Button
                    type="submit"
                    style={{ flexGrow: 3 }}
                    variation="primary"
                >
                    Create
                </Button>
                <Button
                    onClick={props.cancelAdd}
                    style={{ flexGrow: 1 }}
                    variation="primary"
                >
                    Cancel
                </Button>
            </Flex>
        </Flex >
    )
}

export default NewDevice;
