import React, { ChangeEvent, useState } from 'react';
import { Button, Card, Flex, Loader, TextField } from '@aws-amplify/ui-react';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

interface IProps {
    cancelEdit: (() => void),
    device: codegenapi.Device,
    gqlClient: IGQLClient
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string,
}

const EditDevice = (props: IProps): JSX.Element => {

    const [deviceid, setDeviceid] = useState<string>(props.device.deviceid);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setDeviceid(e.target.value);
        e.preventDefault();
    }

    function updateDeviceAndRedirect() {
        if (props.siteid)
            props.gqlClient.gqlUpdateDevice(props.siteid, deviceid)
                .then(props.cancelEdit)
                .catch(response => {
                    props.setErrors(prevState => prevState ? prevState?.concat(response.errors) : response.errors);
                })
    }

    return (
        <Flex>
            <h1>Edit device</h1>
            <Loader variation="linear" style={{ display: props.device && 'none' }} />
            {
                props.device &&
                <Flex>
                    <Card>
                        <TextField
                            label="DeviceID"
                            onChange={handleChange}
                            pattern="[A-Z0-9]+"
                            placeholder="[A-Z0-9]+"
                            required
                            value={deviceid} />
                    </Card>
                    <Flex className="flex-row">
                        <Button
                            onClick={() => updateDeviceAndRedirect()}
                            style={{ flexGrow: 3 }}
                            variation="primary"
                        >
                            Update
                        </Button>
                        <Button
                            onClick={props.cancelEdit}
                            style={{ flexGrow: 1 }}
                            variation="primary"
                        >
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            }
        </Flex >
    )
}

export default EditDevice;
