/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onActivitiesUpdates = /* GraphQL */ `
  subscription OnActivitiesUpdates($subjectid: ID!) {
    onActivitiesUpdates(subjectid: $subjectid) {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
      }
    }
  }
`;
export const onActivityTypesUpdates = /* GraphQL */ `
  subscription OnActivityTypesUpdates($studyid: ID!) {
    onActivityTypesUpdates(studyid: $studyid) {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const onDevicesUpdates = /* GraphQL */ `
  subscription OnDevicesUpdates($siteid: ID!) {
    onDevicesUpdates(siteid: $siteid) {
      siteid
      deviceid
      hw_id
    }
  }
`;
export const onFormTypesUpdates = /* GraphQL */ `
  subscription OnFormTypesUpdates($studyid: ID!) {
    onFormTypesUpdates(studyid: $studyid) {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
      }
    }
  }
`;
export const onFormsUpdates = /* GraphQL */ `
  subscription OnFormsUpdates($subjectid: ID!) {
    onFormsUpdates(subjectid: $subjectid) {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      fields {
        key
        value
      }
    }
  }
`;
export const onPatientsUpdates = /* GraphQL */ `
  subscription OnPatientsUpdates($siteid: ID!) {
    onPatientsUpdates(siteid: $siteid) {
      siteid
      patientid
      name
    }
  }
`;
export const onSitesUpdates = /* GraphQL */ `
  subscription OnSitesUpdates {
    onSitesUpdates {
      siteid
      name
      devices {
        siteid
        deviceid
        hw_id
      }
      patients {
        siteid
        patientid
        name
      }
      studies {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const onStudiesUpdates = /* GraphQL */ `
  subscription OnStudiesUpdates($siteid: ID!) {
    onStudiesUpdates(siteid: $siteid) {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      formtypes {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        forms {
          subjectid
          formtypeid
        }
        activities {
          activitytypeid
          subjectid
          comment
          startTime
          stopTime
        }
        devices {
          deviceid
          position
        }
        snapshot
      }
    }
  }
`;
export const onSubjectsUpdates = /* GraphQL */ `
  subscription OnSubjectsUpdates($studyid: ID!) {
    onSubjectsUpdates(studyid: $studyid) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const onSubjectUpdates = /* GraphQL */ `
  subscription OnSubjectUpdates($studyid: ID!, $subjectid: ID!) {
    onSubjectUpdates(studyid: $studyid, subjectid: $subjectid) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const onReferenceDataUpdates = /* GraphQL */ `
  subscription OnReferenceDataUpdates($subjectid: ID!, $activitytypeid: ID!) {
    onReferenceDataUpdates(
      subjectid: $subjectid
      activitytypeid: $activitytypeid
    ) {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
    }
  }
`;
