import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Flex, Loader, TextField } from '@aws-amplify/ui-react';
import { useParams, useNavigate } from 'react-router-dom';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

interface IProps {
    user: any,
    gqlClient: IGQLClient
}

const EditSite = (props: IProps): JSX.Element => {

    const [sitename, setSitename] = useState<string>('');

    const { siteid } = useParams();

    const navigate = useNavigate();

    useEffect(() => {

        if (siteid)
            props.gqlClient.gqlGetSite(siteid)
                .then(response => {
                    setSitename((response.getSite as codegenapi.Site).name as string);
                }).catch(response => {
                    console.error(response.errors);
                });

    }, [siteid, props.gqlClient]);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setSitename(e.target.value);
        e.preventDefault();
    }

    function updateSiteAndRedirect() {
        if (siteid)
            props.gqlClient.gqlUpdateSite(siteid, sitename)
                .then(() => {
                    navigate('..');
                })
                .catch(response => {
                    console.error(response.errors);
                })
    }

    return (
        <Flex>
            <h1>Edit site</h1>
            <Loader variation="linear" style={{ display: sitename && 'none' }} />
            {
                sitename &&
                <Flex>
                    <Card>
                        <TextField label="Site name" onChange={handleChange} value={sitename} />
                    </Card>
                    <Button
                        onClick={() => updateSiteAndRedirect()}
                        variation="primary">
                        Update</Button>
                </Flex>
            }
        </Flex >
    )
}

export default EditSite;
