import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SignOut(_props) {

    const navigate = useNavigate()

    useEffect(() => {
        navigate('/');
    }, [navigate]);

    return(<></>);
}

export default SignOut;
