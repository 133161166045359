import './App.css';
import React, { useEffect, useState, useMemo } from 'react';
import {
    Auth,
    // Hub
} from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Devices from './pages/Devices';
import EditSite from './components/sites/edit';
import Layout from './components/layout';
import NewSite from './components/sites/new';
import Profile from './pages/Profile';
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';
import Sites from './pages/Sites';
import Studies from './pages/Studies';

import { GQLClient, IGQLClient } from './client/gqlts';

interface IProps {
    auth: (typeof Auth);
    gqlClient: IGQLClient;
}

const App = (props: IProps): JSX.Element => {

    const [siteid, setSiteId] = useState(sessionStorage.getItem('siteid'));
    const [user, setUser] = useState<AmplifyUser | undefined | null>();
    const [userinfo, setUserinfo] = useState(null);
    const [errors, setErrors] = useState<string[] | undefined>();

    const gqlClient: IGQLClient = useMemo(() => {
        if (props.gqlClient)
            return props.gqlClient;
        else
            return new GQLClient();
    }, [props.gqlClient]);

    //  DEBUG subscriptions
    // Hub.listen('api', (data) => {
    //     const { payload } = data;
    //     if (payload.event === CONNECTION_STATE_CHANGE) {
    //         const connectionState = payload.data.connectionState;
    //         console.log(connectionState);
    //     }
    // });

    //  DEBUG auth
    // Hub.listen('auth', ({ payload: { event, data } }) => {
    //     switch (event) {
    //         case 'signIn':
    //         case 'cognitoHostedUI':
    //             // navigate('/');
    //             break;
    //         case 'signOut':
    //             // navigate('/');
    //             break;
    //         case 'signIn_failure':
    //         case 'cognitoHostedUI_failure':
    //             console.error('Sign in failure', data);
    //             break;
    //         default:
    //             console.warn('Event ' + event + ' not handled');
    //             if (data != null)
    //                 console.log(JSON.stringify(data));
    //     }
    // });

    //  Store siteid in session
    useEffect(() => {
        if (siteid)
            sessionStorage.setItem('siteid', siteid)
    }, [siteid]);

    //  Hydrate user
    useEffect(() => {
        props.auth.currentAuthenticatedUser()
            .then((user) => {

                //  Initialize global group membership
                if ('cognito:groups' in user.signInUserSession.idToken.payload) {
                    user.is_admin = user.signInUserSession.idToken.payload['cognito:groups'].includes('admin')
                    user.is_hp = user.signInUserSession.idToken.payload['cognito:groups'].includes('hp')
                    user.is_patient = user.signInUserSession.idToken.payload['cognito:groups'].includes('patient')
                    user.groups = user.signInUserSession.idToken.payload['cognito:groups']
                }

                setUser(user);

                // Deal with refresh token
                props.auth.currentSession();
                props.auth.currentUserInfo().then((userinfo) => {
                    setUserinfo(userinfo);
                });
            })
            .catch(
                () => {
                    setUser(null);
                }
            );

    }, [props.auth]);

    return (
        <BrowserRouter>
            <Routes>
                {
                    user &&
                    <Route path="/*" element={<Layout gqlClient={gqlClient} auth={props.auth} errors={errors} setErrors={setErrors} user={user} siteid={siteid} setSiteId={setSiteId} />}>
                        <Route path="*" element={<Studies gqlClient={gqlClient} user={user} siteid={siteid} setErrors={setErrors} />} />
                        <Route path="profile/*" element={<Profile auth={props.auth} user={user} userinfo={userinfo} changeUserinfo={setUserinfo} />} />
                        <Route path="sites/*" element={<Sites user={user} siteid={siteid} setSiteId={setSiteId} gqlClient={gqlClient} />}>
                            <Route path="new" element={<NewSite user={user} siteid={siteid} gqlClient={gqlClient} setErrors={setErrors} />} />
                            <Route path=":siteid/*" element={<EditSite user={user} gqlClient={gqlClient} />} />
                        </Route>
                        <Route path="studies/*" element={<Studies gqlClient={gqlClient} user={user} siteid={siteid} setErrors={setErrors} />} />
                        <Route path="devices/*" element={<Devices gqlClient={gqlClient} siteid={siteid} setErrors={setErrors} />} />
                        <Route path="signIn" element={<SignIn user={user} auth={props.auth} />} />
                        <Route path="signOut" element={<SignOut user={user} auth={props.auth} />} />
                    </Route>
                }
                {
                    !user &&
                    <Route path="/*" element={<SignIn auth={props.auth} user={user} />} />
                }

            </Routes>
        </BrowserRouter>
    );
}

export default App;
