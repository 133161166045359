import React, { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Outlet, Link, NavLink } from 'react-router-dom';
import { Alert, Button, Divider, Flex, Menu, MenuItem, SelectField } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom'

import * as codegenapi from '../graphql/API';
import { IGQLClient } from '../client/gqlts';

export const Logo = () => (
    <svg width="140" height="30" viewBox="0 0 140 30" className="logo h-full w-64" data-v-55d38626="" data-v-6409935a="">
        <path d="M588.258,616.638a8.609,8.609,0,0,1-3.9-.845,6.887,6.887,0,0,1-2.675-2.292,5.661,5.661,0,0,1-.985-3.258,5,5,0,0,1,1.127-3.419,6.539,6.539,0,0,1,3.659-1.83,33.306,33.306,0,0,1,6.718-.543h1.447v-.684a3.307,3.307,0,0,0-.8-2.514,4,4,0,0,0-2.735-.744,11.967,11.967,0,0,0-2.373.261,15.843,15.843,0,0,0-2.774.865,2.1,2.1,0,0,1-1.891.06,2.037,2.037,0,0,1-1.005-1.287,2.561,2.561,0,0,1,.141-1.75,2.414,2.414,0,0,1,1.508-1.287,19.91,19.91,0,0,1,3.56-1.026,17.377,17.377,0,0,1,3.076-.3q4.586,0,6.837,2.071t2.253,6.415v8.929q0,3.1-2.815,3.1-2.857,0-2.856-3.1v-.241a5.072,5.072,0,0,1-2.031,2.494A6.267,6.267,0,0,1,588.258,616.638Zm1.367-4.062a3.883,3.883,0,0,0,2.856-1.166,4.081,4.081,0,0,0,1.166-3.016v-.8h-1.408a12.423,12.423,0,0,0-4.383.543,1.9,1.9,0,0,0-1.287,1.87,2.4,2.4,0,0,0,.784,1.85A3.234,3.234,0,0,0,589.625,612.575Z" transform="translate(-580.696 -586.754)" className="a a--white" data-v-55d38626=""></path>
        <path d="M611.146,616.536a2.911,2.911,0,0,1-2.272-.865,3.494,3.494,0,0,1-.785-2.433V599.483q0-3.338,3.057-3.338a2.86,2.86,0,0,1,2.232.865,3.557,3.557,0,0,1,.784,2.474v13.755a3.559,3.559,0,0,1-.764,2.433A2.851,2.851,0,0,1,611.146,616.536Z" transform="translate(-586.054 -586.774)" className="a a--white" data-v-55d38626=""></path>
        <path d="M628.287,614.554a8.118,8.118,0,0,1-4.525-1.267,8.412,8.412,0,0,1-3.037-3.58,12.812,12.812,0,0,1-1.085-5.49,12.678,12.678,0,0,1,1.085-5.47,8.011,8.011,0,0,1,7.562-4.786,7.826,7.826,0,0,1,3.62.845,6.012,6.012,0,0,1,2.493,2.252v-8.567q0-3.1,3.016-3.1,3.058,0,3.057,3.1v22.885q0,3.1-3.016,3.1t-3.016-3.1v-.121a5.746,5.746,0,0,1-2.474,2.413A7.734,7.734,0,0,1,628.287,614.554Zm1.81-4.545a4,4,0,0,0,3.157-1.408,6.562,6.562,0,0,0,1.226-4.384,6.409,6.409,0,0,0-1.226-4.324,4.025,4.025,0,0,0-3.157-1.388,3.963,3.963,0,0,0-3.138,1.388,6.482,6.482,0,0,0-1.207,4.324,6.637,6.637,0,0,0,1.207,4.384A3.938,3.938,0,0,0,630.1,610.01Z" transform="translate(-588.313 -584.671)" className="a a--white" data-v-55d38626=""></path>
        <path d="M660.719,616.638a13.222,13.222,0,0,1-6.013-1.267,9.072,9.072,0,0,1-3.861-3.58,11.688,11.688,0,0,1-.04-10.819,9.464,9.464,0,0,1,3.58-3.62,10.339,10.339,0,0,1,5.249-1.307,8.9,8.9,0,0,1,6.838,2.715A10.329,10.329,0,0,1,669,606.1q0,1.529-1.89,1.528H655.29a4.912,4.912,0,0,0,1.709,3.4,6.166,6.166,0,0,0,3.841,1.066,12.45,12.45,0,0,0,2.192-.221,8.68,8.68,0,0,0,2.353-.784,2.372,2.372,0,0,1,1.971-.221,1.945,1.945,0,0,1,1.106,1.146,2.384,2.384,0,0,1,0,1.669,2.655,2.655,0,0,1-1.347,1.428A11.972,11.972,0,0,1,664,616.256,15.014,15.014,0,0,1,660.719,616.638Zm-.845-16.571a4.257,4.257,0,0,0-3.117,1.166,5.425,5.425,0,0,0-1.468,3.258h8.688Q663.736,600.067,659.875,600.067Z" transform="translate(-594.154 -586.754)" className="a a--white" data-v-55d38626=""></path>
        <path d="M688.927,616.638a13.223,13.223,0,0,1-6.013-1.267,9.076,9.076,0,0,1-3.861-3.58,11.691,11.691,0,0,1-.039-10.819,9.463,9.463,0,0,1,3.579-3.62,10.34,10.34,0,0,1,5.249-1.307,8.9,8.9,0,0,1,6.837,2.715,10.329,10.329,0,0,1,2.535,7.34q0,1.529-1.891,1.528H683.5a4.912,4.912,0,0,0,1.709,3.4,6.167,6.167,0,0,0,3.841,1.066,12.451,12.451,0,0,0,2.192-.221,8.684,8.684,0,0,0,2.354-.784,2.37,2.37,0,0,1,1.97-.221,1.943,1.943,0,0,1,1.106,1.146,2.383,2.383,0,0,1,0,1.669,2.655,2.655,0,0,1-1.347,1.428,11.964,11.964,0,0,1-3.117,1.146A15.009,15.009,0,0,1,688.927,616.638Zm-.845-16.571a4.257,4.257,0,0,0-3.117,1.166,5.429,5.429,0,0,0-1.468,3.258h8.688Q691.944,600.067,688.083,600.067Z" transform="translate(-599.671 -586.754)" className="a a--white" data-v-55d38626=""></path>
        <g transform="translate(110.198 9.371)" className="b a--white" data-v-55d38626="">
            <path d="M720.746,616.536a2.915,2.915,0,0,1-2.272-.865,3.5,3.5,0,0,1-.783-2.433V599.483q0-3.338,3.056-3.338a2.86,2.86,0,0,1,2.233.865,3.561,3.561,0,0,1,.783,2.474v13.755a3.555,3.555,0,0,1-.764,2.433A2.85,2.85,0,0,1,720.746,616.536Z" transform="translate(-717.69 -596.145)" className="a a--white" data-v-55d38626=""></path>
        </g>
        <ellipse cx="3.218" cy="3.218" rx="3.218" ry="3.218" transform="translate(100.364 23.328)" className="c a--white" data-v-55d38626=""></ellipse>
        <ellipse cx="3.218" cy="3.218" rx="3.218" ry="3.218" transform="translate(110.016)" className="a a--white" data-v-55d38626=""></ellipse>
        <ellipse cx="3.218" cy="3.218" rx="3.218" ry="3.218" transform="translate(21.854)" className="a a--white" data-v-55d38626=""></ellipse>
        <path d="M739.5,596.145A10.256,10.256,0,1,0,749.751,606.4,10.256,10.256,0,0,0,739.5,596.145Zm0,15.082a4.826,4.826,0,1,1,4.826-4.826A4.827,4.827,0,0,1,739.5,611.227Z" transform="translate(-609.751 -586.774)" className="a a--white" data-v-55d38626=""></path>
    </svg>
);

interface IProps {
    gqlClient: IGQLClient,
    auth: (typeof Auth);
    errors: string[] | undefined,
    siteid: string | null,
    user: any,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    setSiteId: React.Dispatch<React.SetStateAction<string | null>>;
}

const Layout = (props: IProps): JSX.Element => {

    const [sites, setSites] = useState<codegenapi.Site[]>();

    const navigate = useNavigate();

    const { setErrors } = props;

    const refreshSites = useCallback(() => {
        props.gqlClient.gqlListSitesShallow()
            .then(response => setSites(response.listSites as codegenapi.Site[]))
            .catch(response => setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors));
    }, [props.gqlClient, setErrors]);

    useEffect(() => {
        refreshSites();
    }, [refreshSites]);

    return (
        <div className="App">
            <header className="App-header">
                <div className="top-bar">
                    {
                        props.user &&
                        <>
                            <Link to="/">
                                {
                                    Logo()
                                }
                            </Link>
                            <Menu menuAlign="start">
                                {/* <MenuItem onClick={() => navigate('/sites')}> */}
                                {/*     Sites */}
                                {/* </MenuItem> */}
                                {/* <MenuItem onClick={() => navigate('/patients')}> */}
                                {/*     Patients */}
                                {/* </MenuItem> */}
                                {/* <MenuItem onClick={() => navigate('/devices')}> */}
                                {/*     Devices */}
                                {/* </MenuItem> */}
                                <MenuItem onClick={() => navigate('/studies')}>
                                    Studies
                                </MenuItem>
                                <Divider />
                                {
                                    props.user.is_admin && <Button variation="primary" isDisabled className="admin-role">Admin</Button>
                                }
                                {
                                    props.user.is_hp && <Button variation="primary" isDisabled className="hp-role">HP</Button>
                                }
                                <Divider />
                                <MenuItem onClick={() => navigate('/profile')}>
                                    Profile
                                </MenuItem>
                                <Divider />
                                <MenuItem className="logout-button" onClick={() => props.auth.signOut()}>
                                    Sign out
                                </MenuItem>
                            </Menu>
                            <nav>
                                {
                                    props.user.is_admin && <Button variation="primary" isDisabled className="admin-role">Admin</Button>
                                }
                                {
                                    props.user.is_hp && <Button variation="primary" isDisabled className="hp-role">HP</Button>
                                }
                                <Button variation="primary" className="logout-button" onClick={() => props.auth.signOut()}>Sign Out</Button>
                                <NavLink to="profile" className={({ isActive }) => isActive ? 'active_route' : undefined}>
                                    <Button variation="primary">Profile</Button>
                                </NavLink>
                                {
                                    sites && sites.length > 0 &&
                                    <SelectField
                                        placeholder="Select a site..."
                                        label='some'
                                        size="large"
                                        value={props.siteid ?? ''}
                                        onChange={(e) => { props.setSiteId(e.target.value) }}
                                        labelHidden={true}
                                    >
                                        {
                                            sites.map((site) => (
                                                <option key={site.siteid} value={site.siteid}>{site.name}</option>
                                            ))
                                        }
                                    </SelectField>
                                }
                                {/* { */}
                                {/*     props.siteid && (props.user.is_hp || props.user.is_admin) && */}
                                {/*     <NavLink to="devices" className={({ isActive }) => isActive ? 'active_route' : undefined}> */}
                                {/*         <Button variation="primary">Devices</Button> */}
                                {/*     </NavLink> */}
                                {/* } */}
                                {
                                    sites && sites.length > 0 && props.siteid && (props.user.is_hp || props.user.is_admin) &&
                                    <>
                                        {/* <NavLink to="patients" className={({ isActive }) => isActive ? 'active_route' : undefined}> */}
                                        {/*     <Button variation="primary">Patients</Button> */}
                                        {/* </NavLink> */}
                                        <NavLink to="studies" className={({ isActive }) => isActive ? 'active_route' : undefined}>
                                            <Button variation="primary">Studies</Button>
                                        </NavLink>
                                    </>
                                }
                                {
                                    (props.user.is_admin) &&
                                    <NavLink to="devices" className={({ isActive }) => isActive ? 'active_route' : undefined}>
                                        <Button variation="primary">Devices</Button>
                                    </NavLink>
                                }
                                {
                                    (props.user.is_admin) &&
                                    <NavLink to="sites" className={({ isActive }) => isActive ? 'active_route' : undefined}>
                                        <Button variation="primary">Sites</Button>
                                    </NavLink>
                                }
                            </nav>
                        </>
                    }
                </div>
            </header>
            <div className="App-error">
                {
                    props.errors && props.errors.length > 0 &&
                    <Flex
                        style={{ flexDirection: 'column' }}>
                        {
                            props.errors?.map((error, index) => (
                                <Alert
                                    heading="Error"
                                    key={index}
                                    variation="error"
                                >
                                    <pre
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {JSON.stringify(error)}
                                    </pre>
                                    <Button
                                        onClick={() => { error && navigator.clipboard.writeText(error) }}
                                    >
                                        Copy to clipboard
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            props.setErrors(prevState => prevState?.filter(err => err !== error));
                                        }}
                                    >
                                        Dismiss
                                    </Button>
                                </Alert>
                            ))
                        }
                    </Flex>
                }
            </div>
            <div className="App-body">
                <Outlet />
            </div>
        </div >
    )
}

export default Layout
