/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevice = /* GraphQL */ `
  query GetDevice($siteid: ID!, $deviceid: String!) {
    getDevice(siteid: $siteid, deviceid: $deviceid) {
      siteid
      deviceid
      hw_id
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices($siteid: ID) {
    listDevices(siteid: $siteid) {
      siteid
      deviceid
      hw_id
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($siteid: ID!, $patientid: String!) {
    getPatient(siteid: $siteid, patientid: $patientid) {
      siteid
      patientid
      name
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients($siteid: ID) {
    listPatients(siteid: $siteid) {
      siteid
      patientid
      name
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($siteid: ID!) {
    getSite(siteid: $siteid) {
      siteid
      name
      devices {
        siteid
        deviceid
        hw_id
      }
      patients {
        siteid
        patientid
        name
      }
      studies {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites {
    listSites {
      siteid
      name
      devices {
        siteid
        deviceid
        hw_id
      }
      patients {
        siteid
        patientid
        name
      }
      studies {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const getStudy = /* GraphQL */ `
  query GetStudy($siteid: ID!, $studyid: ID!) {
    getStudy(siteid: $siteid, studyid: $studyid) {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      formtypes {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        forms {
          subjectid
          formtypeid
        }
        activities {
          activitytypeid
          subjectid
          comment
          startTime
          stopTime
        }
        devices {
          deviceid
          position
        }
        snapshot
      }
    }
  }
`;
export const listStudies = /* GraphQL */ `
  query ListStudies($siteid: ID) {
    listStudies(siteid: $siteid) {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      formtypes {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        forms {
          subjectid
          formtypeid
        }
        activities {
          activitytypeid
          subjectid
          comment
          startTime
          stopTime
        }
        devices {
          deviceid
          position
        }
        snapshot
      }
    }
  }
`;
export const getSubject = /* GraphQL */ `
  query GetSubject($studyid: ID!, $subjectid: ID!) {
    getSubject(studyid: $studyid, subjectid: $subjectid) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const listSubjects = /* GraphQL */ `
  query ListSubjects($studyid: ID) {
    listSubjects(studyid: $studyid) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($subjectid: ID!, $formtypeid: ID!) {
    getForm(subjectid: $subjectid, formtypeid: $formtypeid) {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      fields {
        key
        value
      }
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms($subjectid: ID) {
    listForms(subjectid: $subjectid) {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      fields {
        key
        value
      }
    }
  }
`;
export const getFormType = /* GraphQL */ `
  query GetFormType($studyid: ID!, $formtypeid: ID!) {
    getFormType(studyid: $studyid, formtypeid: $formtypeid) {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
      }
    }
  }
`;
export const listFormTypes = /* GraphQL */ `
  query ListFormTypes($studyid: ID) {
    listFormTypes(studyid: $studyid) {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
      }
    }
  }
`;
export const getReferenceData = /* GraphQL */ `
  query GetReferenceData($subjectid: ID!, $referencedataid: ID!) {
    getReferenceData(subjectid: $subjectid, referencedataid: $referencedataid) {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
    }
  }
`;
export const listReferenceData = /* GraphQL */ `
  query ListReferenceData($subjectid: ID) {
    listReferenceData(subjectid: $subjectid) {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($subjectid: ID!, $activitytypeid: ID!) {
    getActivity(subjectid: $subjectid, activitytypeid: $activitytypeid) {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
      }
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities($subjectid: ID) {
    listActivities(subjectid: $subjectid) {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
      }
    }
  }
`;
export const getActivityType = /* GraphQL */ `
  query GetActivityType($studyid: ID!, $activitytypeid: ID!) {
    getActivityType(studyid: $studyid, activitytypeid: $activitytypeid) {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const listActivityTypes = /* GraphQL */ `
  query ListActivityTypes($studyid: ID) {
    listActivityTypes(studyid: $studyid) {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
