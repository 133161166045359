import React, { ChangeEvent, useState } from 'react';
import { Button, Card, Flex, TextField } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom'

import { IGQLClient } from '../../client/gqlts';

interface IProps {
    cancelAdd: () => void,
    gqlClient: IGQLClient,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    siteid: string | null,
}

const NewSubject = (props: IProps): JSX.Element => {

    const [identifier, setIdentifier] = useState('');

    const { studyid } = useParams();

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setIdentifier(e.target.value);
        e.preventDefault()
    }

    function addSubject() {
        if (props.siteid && studyid && identifier)
            props.gqlClient.gqlAddSubjectV2(props.siteid, studyid, identifier, undefined)
                .then(props.cancelAdd)
                .catch(response => {
                    props.setErrors(prevState => prevState ? prevState.concat(response.errors) : response.errors)
                });
    }


    return (
        <Flex>
            <h1>New subject</h1>
            <Card>
                <TextField
                    label="Identifier"
                    onChange={handleChange}
                    onKeyDown={(e) => { if (e.key === 'Enter') addSubject() }}
                    value={identifier}
                />
            </Card>
            <Flex className="flex-row">
                <Button
                    onClick={() => addSubject()}
                    style={{ flexGrow: 3 }}
                    variation="primary"
                >
                    Create
                </Button>
                <Button
                    onClick={() => props.cancelAdd()}
                    style={{ flexGrow: 1 }}
                    variation="primary"
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    )
}

export default NewSubject;
