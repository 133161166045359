import React, { useEffect, useState } from 'react';
import { Alert, Button, Flex } from '@aws-amplify/ui-react';
import { Route, Routes } from 'react-router-dom'

import NewStudy from '../components/studies/new';
import StudiesList from '../components/studies/list';
import Study from '../components/studies/study';

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';
import { Observable, Subscription } from 'rxjs';

interface IProps {
    gqlClient: IGQLClient,
    siteid: string | null,
    user: any,
    setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const Studies = (props: IProps): JSX.Element => {

    const [studies, setStudies] = useState<codegenapi.Study[]>();

    const [newStudy, setNewStudy] = useState<codegenapi.Study>();

    const { setErrors } = props;

    useEffect(() => {

        //  Updates list of Studies
        function refreshStudies(siteid: string) {
            props.gqlClient.gqlListStudies(siteid)
                .then(response => {
                    setStudies(response.listStudies as codegenapi.Study[]);
                })
                .catch(response => {
                    setErrors(prevState => prevState ? response.errors.concat(prevState) : response.errors);
                });
        }

        let studiesSubscription: Subscription;

        if (props.siteid) {

            refreshStudies(props.siteid);

            props.gqlClient.gqlOnStudiesUpdatesSubscription(props.siteid)
                .then(subscription => {
                    studiesSubscription = (subscription as Observable<codegenapi.OnStudiesUpdatesSubscription>).subscribe({
                        next: () => {
                            if (props.siteid)
                                //  The data returned by the subscrition isn't complete
                                refreshStudies(props.siteid);
                        },
                        error: response => setErrors(prevState => prevState ? prevState?.concat(response.error) : [response.error])
                    })
                })
                .catch(response => {
                    setErrors(prevState => prevState ? response.errors.concat(prevState) : response.errors);
                });
        }

        return () => {
            studiesSubscription?.unsubscribe();
        }

    }, [props.siteid, props.gqlClient, setErrors]);

    return (
        <Flex>
            <Routes>
                <Route path="*" element={
                    <Flex className="flex-row">
                        {
                            !props.siteid &&
                            <Alert
                                heading="Site not selected"
                                variation="info"
                            >
                                Please select site in the menu above
                            </Alert>
                        }
                        {
                            props.siteid &&
                            <Flex>
                                <StudiesList
                                    gqlClient={props.gqlClient}
                                    studies={studies}
                                    user={props.user}
                                />
                                <Flex>
                                    <Button
                                        variation="primary"
                                        onClick={() => setNewStudy({} as codegenapi.Study)}
                                    >
                                        New study
                                    </Button>
                                </Flex>
                            </Flex>
                        }
                        {
                            props.siteid && newStudy &&
                            <Flex>
                                <NewStudy
                                    cancelAdd={() => (setNewStudy(undefined))}
                                    gqlClient={props.gqlClient}
                                    setErrors={props.setErrors}
                                    siteid={props.siteid}
                                />
                            </Flex>
                        }
                    </Flex>
                } />
                <Route path=":studyid/*" element={
                    props.siteid &&
                    <Study
                        gqlClient={props.gqlClient}
                        setErrors={props.setErrors}
                        siteid={props.siteid}
                        studies={studies}
                        user={props.user}
                    />
                } />
            </Routes>
        </Flex >
    )
}

export default Studies
