import { Observable, Subscription } from 'rxjs';
import React, { useEffect, useState } from 'react';
import { Button, Flex, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdDelete, MdEdit } from 'react-icons/md';

import EditForm from '../components/forms/edit';
import NewForm from '../components/forms/new';

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';

interface IProps {
    date: number | undefined,
    gqlClient: IGQLClient,
    setDate: React.Dispatch<React.SetStateAction<number | undefined>>,
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
    singleFormType: codegenapi.FormType | undefined,
}

const Forms = (props: IProps): JSX.Element => {

    const [forms, setForms] = useState<codegenapi.Form[]>();

    const [editingFormTypeId, setEditingFormTypeId] = useState<string | undefined>();
    const [newForm, setNewForm] = useState<codegenapi.Form | undefined>();

    const { setDate } = props;

    useEffect(() => {

        async function refreshForms(subjectid: string) {
            props.gqlClient.gqlListForms(subjectid)
                .then(response => {
                    setForms(response.listForms as codegenapi.Form[]);

                    // extract date
                    if (response.listForms)
                        for (let form of response.listForms) {
                            const dateField = form.fields?.find(field => field.key.toLowerCase() === 'date');
                            if (dateField) {
                                setDate(Number.parseInt(dateField.value));
                                break;
                            }
                        }

                }).catch(response => {
                    console.error(response.errors);
                });
        }

        let formsSubscription: Subscription;

        if (props.subject?.subjectid) {

            refreshForms(props.subject.subjectid);

            props.gqlClient.gqlOnFormsUpdatesSubscription(props.subject.subjectid)
                .then(subscription => {
                    formsSubscription = (subscription as Observable<codegenapi.OnFormTypesUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            if (props.subject)
                                refreshForms(props.subject.subjectid);
                        },
                        error: error => console.warn(error)
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }

        return () => {
            formsSubscription?.unsubscribe();
        }

    }, [props.gqlClient, props.subject, setDate]);

    //  Show edit one form type if given
    useEffect(() => {
        if (props.singleFormType)
            if (forms?.length === 0)
                setNewForm({ formtype: props.singleFormType } as codegenapi.Form);
            else
                setEditingFormTypeId(props.singleFormType.formtypeid);
    }, [forms, props.singleFormType]);

    function cancelNew() {
        setNewForm(undefined);
    }

    function cancelEdit() {
        setEditingFormTypeId(undefined);
    }

    return (
        <Flex className="flex-row">
            {
                !props.singleFormType && forms &&
                <Flex>
                    <h1>Forms</h1>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Form type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                forms.length > 0 && forms.map((form) => (
                                    <TableRow key={form.formtype?.formtypeid} style={{ backgroundColor: form.formtypeid === editingFormTypeId ? 'lightcyan' : 'white' }}>
                                        <TableCell>
                                            {form.formtype?.identifier}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                                onClick={() => setEditingFormTypeId(form.formtype?.formtypeid)}
                                            >
                                                <MdEdit />
                                            </Button>
                                            <Button
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                                onClick={() => (props.gqlClient.gqlRemoveForm(props.subject?.subjectid as string, form.formtypeid))}
                                            >
                                                <MdDelete />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            {
                                forms.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={2}><i>No forms yet!</i></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Flex>
                        <Button
                            disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                            onClick={() => setNewForm({} as codegenapi.Form)}
                            variation="primary"
                        >
                            Add form
                        </Button>
                    </Flex>
                </Flex>
            }

            {
                editingFormTypeId && forms &&
                <EditForm
                    cancelEdit={cancelEdit}
                    editingFormTypeId={editingFormTypeId}
                    forms={forms}
                    gqlClient={props.gqlClient}
                    singleFormType={props.singleFormType}
                    siteid={props.siteid}
                    subject={props.subject}
                />
            }

            {
                newForm && forms?.length === 0 &&
                <NewForm
                    cancelNew={cancelNew}
                    gqlClient={props.gqlClient}
                    singleFormType={props.singleFormType}
                    siteid={props.siteid}
                    subject={props.subject}
                />
            }

        </Flex>
    )
}

export default Forms;
