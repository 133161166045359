import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Flex, TextField } from '@aws-amplify/ui-react';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

export enum Op {
    NEW,
    UPDATE
}

interface IProps {
    activityType: codegenapi.ActivityType | undefined,
    cancelEdit: () => void,
    gqlClient: IGQLClient
    newActivityType: null | ((studyid: string, activityType: codegenapi.ActivityType) => void),
    siteid: string | null,
    studyid: string,
    updateActivityType: null | ((studyid: string, activitytypeid: string, activityType: codegenapi.ActivityType) => void),
    op: Op,
}

const EditActivityType = (props: IProps): JSX.Element => {

    const [localActivityType, setLocalActivityType] = useState<codegenapi.ActivityType | undefined>(props?.activityType);

    useEffect(() => {
        if (props.activityType)
            if (props.activityType.description)
                setLocalActivityType(props.activityType);
            else
                setLocalActivityType({ ...props.activityType, description: '' });
    }, [props.activityType]);

    function newActivityType() {
        if (localActivityType && props.newActivityType)
            props.newActivityType(props.studyid, localActivityType)
    }

    function updateActivityType() {
        if (localActivityType && props.activityType?.activitytypeid && props.updateActivityType)
            props.updateActivityType(props.studyid, props.activityType?.activitytypeid, localActivityType)
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setLocalActivityType(prevState => {
            if (prevState)
                return { ...prevState, description: e.target.value }
            else
                return { description: e.target.value } as codegenapi.ActivityType
        });
        e.preventDefault();
    }

    return (
        <Flex>
            {
                props.op === Op.NEW &&
                <h1>New activity type</h1>
            }
            {
                props.op === Op.UPDATE &&
                <h1>Edit activity type</h1>
            }
            <Flex>
                <Card>
                    <TextField
                        label="Description"
                        onChange={handleChange}
                        onKeyDown={
                            (e) => {
                                if (e.key === 'Enter')
                                    if (props.op === Op.NEW)
                                        newActivityType();
                                    else if (props.op === Op.UPDATE)
                                        updateActivityType();
                            }
                        }
                        value={localActivityType?.description ?? ''}
                    />
                </Card>
                <Flex className="flex-row-fill">
                    {
                        props.op === Op.NEW &&
                        <Button
                            onClick={() => newActivityType()}
                            style={{
                                flexGrow: 2
                            }}
                            variation="primary"
                        >
                            Add
                        </Button>
                    }
                    {
                        props.op === Op.UPDATE &&
                        <Button
                            onClick={() => updateActivityType()}
                            style={{
                                flexGrow: 2
                            }}
                            variation="primary"
                        >
                            Update
                        </Button>
                    }
                    <Button
                        onClick={() => props.cancelEdit()}
                        style={{
                            flexGrow: 1
                        }}
                        variation="primary"
                    >
                        Cancel
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default EditActivityType;
