import React from 'react';
import { useParams } from 'react-router-dom'
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdDelete } from 'react-icons/md';

import * as codegenapi from '../../graphql/API';
import { IGQLClient } from '../../client/gqlts';

interface IProps {
    devices: codegenapi.Device[] | undefined,
    gqlClient: IGQLClient,
    setEditingDevice: React.Dispatch<React.SetStateAction<codegenapi.Device | undefined>>;
    siteid: string,
}

const DevicesList = (props: IProps): JSX.Element => {

    const { deviceid } = useParams();

    return (
        <Flex>
            <h1>Devices</h1>
            <Loader variation="linear" style={{ display: props.devices && 'none' }} />
            {
                props.devices &&
                <Flex>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">DeviceID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.devices.length > 0 &&
                                props.devices
                                    .sort((a, b) => a.deviceid.localeCompare(b.deviceid))
                                    .map((device) => (
                                        <TableRow key={device.deviceid} style={{ backgroundColor: device.deviceid === deviceid ? 'lightcyan' : 'white' }}>
                                            <TableCell>
                                                {device.deviceid}
                                            </TableCell>
                                            <TableCell>
                                                {/* <Button onClick={() => props.setEditingDevice(device)}> */}
                                                {/*     <MdEdit /> */}
                                                {/* </Button> */}
                                                <Button onClick={() => (props.siteid && props.gqlClient.gqlRemoveDevice(props.siteid, device.deviceid))}>
                                                    <MdDelete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                            {
                                props.devices.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={2}><i>No activity types yet!</i></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Flex>
            }
        </Flex>
    )
}

export default DevicesList;
