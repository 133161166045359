import React, { useEffect, useState } from 'react';
import { Button, Card, Flex, TextField, CheckboxField, Text } from '@aws-amplify/ui-react';

//  TODO: migrate to TS
function Userinfo(props) {

    //  anti-pattern, but we don't want to update the props until the API call is OK
    const [state, setState] = useState({
        sub: props.userinfo.attributes.sub,
        given_name: props.userinfo.attributes.given_name,
        family_name: props.userinfo.attributes.family_name,
        email: props.userinfo.attributes.email,
        email_verified: props.userinfo.attributes.email_verified,
        phone_number: props.userinfo.attributes.phone_number,
        phone_number_verified: props.userinfo.attributes.phone_number_verified,
        isUpdating: false
    });

    //  we must update the interface whenever userinfo is updated e.g. from VerifyMail
    useEffect(() => {
        setState({
            sub: props.userinfo.attributes.sub,
            given_name: props.userinfo.attributes.given_name,
            family_name: props.userinfo.attributes.family_name,
            email: props.userinfo.attributes.email,
            email_verified: props.userinfo.attributes.email_verified,
            phone_number: props.userinfo.attributes.phone_number,
            phone_number_verified: props.userinfo.attributes.phone_number_verified,
            isUpdating: false
        });
    }, [props.userinfo]);

    //  happens on every character change
    function handleChange(e) {
        setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        e.preventDefault()
    }

    function onUpdateUserinfoClick() {
        setState(prevState => ({ ...prevState, isUpdating: true }));

        let attributes = {
            given_name: state.given_name,
            family_name: state.family_name,
        }
        if (state.phone_number)
            attributes = { ...attributes, phone_number: state.phone_number }
        if (state.email)
            attributes = { ...attributes, email: state.email }

        props.auth.updateUserAttributes(props.user, attributes)
            .then(() => {
                props.auth.currentUserInfo().then((userinfo) => {
                    //  update props
                    props.changeUserinfo(userinfo);
                });
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isUpdating: false }));
            });
    }

    return (
        <Flex className="flex-row">
            <Flex>
                <Card>
                    <TextField label='First name' name='given_name' value={state.given_name} onChange={handleChange} />
                    <TextField label='Last name' name='family_name' value={state.family_name} onChange={handleChange} />
                    <TextField label='Email address' name='email' value={state.email} onChange={handleChange} />
                    <CheckboxField
                        checked={state.email_verified}
                        disabled={true}
                        label="Email verified"
                        name='email_verified'
                    />
                    <TextField label='Phone number' name='phone_number' value={state.phone_number} onChange={handleChange} />
                    <CheckboxField
                        checked={state.phone_number_verified}
                        disabled={true}
                        label="Phone number verified"
                        name='phone_number_verified'
                    />
                    <Text style={{ margin: '1rem 0 0 0' }}>Roles</Text>
                    <CheckboxField
                        checked={props.user.is_admin}
                        disabled={true}
                        label="Global administrator"
                    />
                    <CheckboxField
                        checked={props.user.is_hp}
                        disabled={true}
                        label="Health personnel"
                    />
                    <CheckboxField
                        checked={props.user.is_patient}
                        disabled={true}
                        label="Patient"
                    />
                    <Button variation="primary" className="submit-button" isLoading={state.isUpdating} loadingText='Updating...' onClick={onUpdateUserinfoClick}>Update</Button>
                </Card>
            </Flex>
            <Flex>
                <Card>
                    <TextField
                        label='Subject'
                        name='sub'
                        value={state.sub}
                        isDisabled={true}
                    />
                    <Button
                        className="submit-button"
                        onClick={() => { navigator.clipboard.writeText(state.sub) }}
                    >
                        Copy to clipboard
                    </Button>
                    <TextField
                        label="Permissions"
                        value={props.user.groups?.join(",")}
                        isDisabled
                    />
                    <Button
                        className="submit-button"
                        onClick={() => { navigator.clipboard.writeText(props.user.groups.join(",")) }}
                    >
                        Copy to clipboard
                    </Button>
                </Card>
            </Flex>
        </Flex>
    )
}

export default Userinfo;
