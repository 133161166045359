import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";

import * as codegenapi from "../../graphql/API";
import { IGQLClient } from "../../client/gqlts";
import { MdDelete } from "react-icons/md";

interface IProps {
  gqlClient: IGQLClient;
  setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  siteid: string | null;
  subjects: codegenapi.Subject[] | undefined;
  setEditingSubject: React.Dispatch<
    React.SetStateAction<codegenapi.Subject | undefined>
  >;
  user: any;
}

const SubjectsList = (props: IProps): JSX.Element => {
  const navigate = useNavigate();

  const { subjectid } = useParams();

  function deleteSubject(subject: codegenapi.Subject): void {
    props.gqlClient
      .gqlRemoveSubject(subject.studyid, subject.subjectid)
      .catch((response) => {
        props.setErrors((prevState) =>
          prevState ? prevState.concat(response.errors) : response.errors
        );
      });
  }

  return (
    <Flex>
      <h1>Subjects</h1>
      <Loader
        variation="linear"
        style={{ display: props.subjects && "none" }}
      />
      {props.subjects && (
        <Flex>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell as="th">&nbsp;</TableCell>
                <TableCell as="th">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.subjects &&
                props.subjects.length > 0 &&
                props.subjects.map((subject) => (
                  <TableRow
                    key={subject.subjectid}
                    style={{
                      backgroundColor:
                        subject.subjectid === subjectid ? "lightcyan" : "white",
                    }}
                  >
                    <TableCell>
                      <Button
                        onClick={() => navigate(`./${subject.subjectid}`)}
                      >
                        {subject.identifier
                          ? subject.identifier
                          : subject.subjectid}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          color:
                            subject.status === codegenapi.SubjectStatusType.OPEN
                              ? "green"
                              : "darkred",
                        }}
                      >
                        {subject.status}
                      </b>
                    </TableCell>
                    {props.user &&
                      props.user.groups.includes("subject:admin") && (
                        <TableCell>
                          <Button
                            className="table-action-button"
                            disabled={
                              subject.status ===
                              codegenapi.SubjectStatusType.DELETED
                            }
                            onClick={() => deleteSubject(subject)}
                            title="Mark as deleted"
                          >
                            <MdDelete />
                          </Button>
                        </TableCell>
                      )}
                  </TableRow>
                ))}
              {(!props.subjects || props.subjects.length === 0) && (
                <TableRow key="empty">
                  <TableCell colSpan={5}>
                    <i>No subjects yet!</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Flex>
      )}
    </Flex>
  );
};

export default SubjectsList;
