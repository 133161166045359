import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Flex, SelectField } from '@aws-amplify/ui-react';

import FormFieldList from './fieldlist';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

interface IProps {
    cancelNew: () => void,
    gqlClient: IGQLClient
    singleFormType: codegenapi.FormType | undefined,
    siteid: string | null,
    subject: codegenapi.Subject | undefined,
}

const NewForm = (props: IProps): JSX.Element => {

    const [formtypeid, setFormtypeid] = useState<string | undefined>(props.singleFormType?.formtypeid);
    const [formtypes, setFormTypes] = useState<codegenapi.FormType[]>([]);

    const [fields, setFields] = useState<codegenapi.KeyValuePair[]>([]);
    const [formtype, setFormType] = useState<codegenapi.FormType>();

    useEffect(() => {

        function refreshFormTypes(studyid: string) {
            if (studyid)
                props.gqlClient.gqlListFormTypes(studyid)
                    .then(response => {
                        setFormTypes(response.listFormTypes as codegenapi.FormType[]);
                    }).catch(response => {
                        console.error(response.errors);
                    });
        }

        if (props.siteid && props.subject?.studyid)
            refreshFormTypes(props.subject?.studyid);

    }, [props.siteid, props.subject, props.gqlClient]);

    useEffect(() => {
        if (formtypes && formtypeid)
            setFormType(formtypes.find(formtype => formtype.formtypeid === formtypeid));
    }, [formtypes, formtypeid]);

    function addForm(fields: codegenapi.KeyValuePair[]) {
        if (props.subject?.subjectid && formtypeid)
            props.gqlClient.gqlAddForm(props.subject?.subjectid, formtypeid, fields)
                .then(_response => {
                    props.cancelNew();
                }).catch(response => {
                    console.error(response.errors);
                });
    }

    function handleFormTypeSelected(e: ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        setFormtypeid(e.target.value);
    }

    return (
        <Flex>
            {
                !props.singleFormType &&
                <h1>New form</h1>
            }

            {
                props.singleFormType &&
                <h1>{props.singleFormType.identifier}</h1>
            }

            {
                formtypes &&
                <Flex>
                    {
                        !props.singleFormType &&
                        <Card style={{ width: '100%' }}>
                            <SelectField
                                id='formtype-select'
                                onChange={handleFormTypeSelected}
                                label="Form type"
                                placeholder="Select form type..."
                            >
                                {
                                    formtypes && formtypes.length > 0 && formtypes.map((formtype) => (
                                        <option key={formtype.formtypeid} value={formtype.formtypeid}>{formtype.identifier}</option>
                                    ))
                                }
                            </SelectField>
                        </Card>
                    }

                    {
                        !formtype &&
                        <Button variation="primary"
                            onClick={() => props.cancelNew()}
                        >
                            Cancel
                        </Button>

                    }

                    {
                        formtype &&
                        <FormFieldList
                            cancel={props.singleFormType ? undefined : props.cancelNew}
                            fields={fields}
                            formtype={formtype}
                            setFields={setFields}
                            subject={props.subject}
                            updateForm={addForm}
                        />
                    }
                </Flex>
            }
        </Flex >
    )
}

export default NewForm;
