import React from 'react';
import Userinfo from '../components/userinfo'
import VerifyEmail from '../components/verifyEmail'
import { Flex } from '@aws-amplify/ui-react';

function Profile(props) {

    return (
        <Flex>
            <h1>Profile</h1>
            {
                props.user && props.userinfo &&
                <Flex className="userinfo-cards">
                    <Userinfo auth={props.auth} user={props.user} userinfo={props.userinfo} changeUserinfo={props.changeUserinfo} />
                    {
                        props.userinfo.attributes.email && !props.userinfo.attributes.email_verified && <VerifyEmail auth={props.auth} user={props.user} userinfo={props.userinfo} changeUserinfo={props.changeUserinfo} />
                    }
                    {
                        // !props.userinfo.phone_number_verified && <VerifyPhone auth={props.auth} user={props.user} userinfo={props.userinfo} changeUserinfo={props.changeUserinfo} />
                    }
                </Flex>
            }
        </Flex>
    )
}

export default Profile;
