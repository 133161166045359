import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Flex, Loader, SelectField, Table, TableBody, TableCell, TableHead, TableRow, Text, TextField } from '@aws-amplify/ui-react';

import * as codegenapi from '../../graphql/API';

interface IProps {
    cancel: undefined | (() => void),
    fields: codegenapi.KeyValuePair[],
    formtype: codegenapi.FormType,
    setFields: React.Dispatch<React.SetStateAction<codegenapi.KeyValuePair[]>>,
    subject: codegenapi.Subject | undefined,
    updateForm: undefined | ((fields: codegenapi.KeyValuePair[]) => void),
}

const FormFieldsList = (props: IProps): JSX.Element => {

    //  local state to deal with controlled input fields
    //  initialize to props
    const [localFields, setLocalFields] = useState<codegenapi.KeyValuePair[]>();

    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    //  update local state with props and initialize certain types
    useEffect(() => {

        if (props.fields) {

            const initialFields: codegenapi.KeyValuePair[] = props.fields;

            //  initialize date fields
            if (props.formtype.fields)
                for (let ftfield of props.formtype.fields.filter(ftField => ["Date"].includes(ftField.type)))
                    if (!initialFields.find(field => field.key === ftfield.name)) {
                        initialFields.push(
                            {
                                key: ftfield.name,
                                value: (() => {
                                    const now = new Date();
                                    const todayAtUtcNoon = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 12, 0); //  UTC noon
                                    return (Math.floor(todayAtUtcNoon / 1000)).toString();   // convert to seconds
                                })()
                            } as codegenapi.KeyValuePair
                        );
                        setUnsavedChanges(true);
                    }

            setLocalFields(initialFields);
        }

    }, [props.fields, props.formtype]);

    //  updates local state on every input change
    function handleChange(e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) {

        setLocalFields(
            (prevState: codegenapi.KeyValuePair[] | undefined) => {

                //  list of fields undefined
                if (!prevState) {
                    return [
                        {
                            key: e.target.name,
                            value: e.target.value,
                        } as codegenapi.KeyValuePair
                    ];
                }

                //  updating value on existing field
                else if (prevState.find(field => field.key === e.target.name)) {
                    return prevState.map(
                        field => field.key === e.target.name ? (() => { //  field being edited, update with value
                            const fieldType = props.formtype.fields?.find(ftField => ftField.name === field.key)?.type as string;
                            if (!["Date"].includes(fieldType))
                                return { key: e.target.name, value: e.target.value } as codegenapi.KeyValuePair
                            else {
                                //  convert dates from the form (YYYY-MM-DD) to UTC epoch
                                const localDate = new Date(Date.parse(e.target.value));
                                const utcNoon = Math.floor(Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), 12, 0).valueOf() / 1000);
                                return { key: e.target.name, value: utcNoon.toString() } as codegenapi.KeyValuePair;
                            }
                        })()
                            : field //  field not being edited, pass through
                    );
                }

                //  add new field
                else {
                    return [
                        ...prevState,
                        { key: e.target.name, value: e.target.value } as codegenapi.KeyValuePair
                    ];
                }
            });

        setUnsavedChanges(true);

        e.preventDefault()
    }

    //  only triggered when the form is valid
    function postValidation(e: FormEvent<HTMLDivElement>) {

        //  prevent built-in submit
        e.preventDefault();

        //  invoke API call in parent
        if (props.updateForm && localFields)
            props.updateForm(localFields);
    }

    return (
        <Flex as="form" onSubmit={postValidation}>
            <Loader variation="linear" style={{ display: props.formtype && 'none' }} />
            {
                props.formtype &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">Name</TableCell>
                            <TableCell as="th">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.formtype && props.formtype.fields && props.formtype.fields.map((formfield: codegenapi.FormTypeField) => (
                                <TableRow key={formfield.name}>
                                    <TableCell>
                                        <Text>{formfield.name}</Text>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            formfield.type === 'Boolean' &&
                                            <SelectField
                                                name={formfield.name}
                                                isRequired={formfield.required}
                                                onChange={handleChange}
                                                label=""
                                                variation="quiet"
                                                placeholder="Select"
                                                value={localFields?.find(field => field.key === formfield.name)?.value}
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            >
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </SelectField>
                                        }
                                        {
                                            formfield.type === 'Choice' &&
                                            <SelectField
                                                name={formfield.name}
                                                isRequired={formfield.required}
                                                onChange={handleChange}
                                                label=""
                                                variation="quiet"
                                                value={localFields?.find(field => field.key === formfield.name)?.value ?? ''}
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            >
                                                {
                                                    !formfield.required &&
                                                    <option value=""></option>
                                                }
                                                {
                                                    formfield.pattern?.split('|').map(option => (
                                                        <option key={option} value={option}>{option}</option>
                                                    ))
                                                }
                                            </SelectField>
                                        }
                                        {
                                            formfield.type === 'Date' &&
                                            <TextField
                                                name={formfield.name}
                                                type="date"
                                                isRequired={formfield.required}
                                                onChange={handleChange}
                                                label=""
                                                variation="quiet"
                                                value={((): string => {
                                                    //  convert epoch to local date
                                                    const epochDateStr: string = localFields?.find(field => field.key === formfield.name)?.value ?? '';
                                                    const epochDate: number = Number.parseInt(epochDateStr);
                                                    if (epochDate)
                                                        return new Date(epochDate * 1000).toISOString().split('T')[0];
                                                    return localFields?.find(field => field.key === formfield.name)?.value ?? ''
                                                })()
                                                }
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            >
                                            </TextField>
                                        }
                                        {
                                            formfield.type === 'Time' &&
                                            <TextField
                                                name={formfield.name}
                                                // type="time"
                                                pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                                                isRequired={formfield.required}
                                                onChange={handleChange}
                                                label=""
                                                variation="quiet"
                                                value={localFields?.find(field => field.key === formfield.name)?.value ?? ''}
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            >
                                            </TextField>
                                        }
                                        {
                                            formfield.type === 'Number' &&
                                            <TextField
                                                name={formfield.name}
                                                type="number"
                                                isRequired={formfield.required}
                                                onChange={handleChange}
                                                label=""
                                                variation="quiet"
                                                value={localFields?.find(field => field.key === formfield.name)?.value ?? ''}
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            >
                                            </TextField>
                                        }
                                        {
                                            formfield.type === 'String' &&
                                            <TextField
                                                name={formfield.name}
                                                maxLength={formfield.length?.valueOf()}
                                                isRequired={formfield.required}
                                                pattern={formfield.pattern?.toString()}
                                                onChange={handleChange}
                                                label=""
                                                variation="quiet"
                                                value={localFields?.find(field => field.key === formfield.name)?.value ?? ''}
                                                placeholder={formfield.pattern?.valueOf()}
                                                disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                                            >
                                            </TextField>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            }
            <Flex className="flex-row">
                <Button
                    disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                    style={{ flexGrow: 3, backgroundColor: unsavedChanges ? 'darkred' : '' }}
                    type='submit'
                    variation='primary'
                >
                    Save
                </Button>
                {
                    props.cancel &&
                    <Button
                        disabled={props.subject?.status === codegenapi.SubjectStatusType.CLOSED}
                        onClick={props.cancel}
                        style={{ flexGrow: 1 }}
                        variation="primary"
                    >
                        Cancel
                    </Button>
                }
            </Flex>
        </Flex >
    )
}

export default FormFieldsList;
