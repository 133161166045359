import { Button, SelectField, TableCell, TableRow, Text, TextField } from '@aws-amplify/ui-react';
import { MdCancel, MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import React, { useState, useEffect } from 'react';

function FieldRow(props) {

    const { field, setField } = props;

    const [readonly, setReadonly] = useState(props.op === 'edit');
    const [localField, setLocalField] = useState(props?.field);

    function handleChange(e) {
        if (e.target.name === 'required')
            setLocalField(prevState => ({ ...prevState, [e.target.name]: e.target.value === 'true' ? true : false }));
        else if (e.target.name === 'length')
            setLocalField(prevState => ({ ...prevState, [e.target.name]: parseInt(e.target.value) }));
        else
            setLocalField(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        e.preventDefault()
    }

    //  Handle changes to the field being edited
    useEffect(() => {
        if (props.op==='new')
            return;

        if (props?.editingFieldName === field?.name) {
            setReadonly(false);
            setField(localField);
        }
        else {
            setReadonly(true);
            setLocalField(field);
        }
    }, [props.editingFieldName, props.op, field, localField, setField]);

    //  Update props with local fields
    useEffect(() => {
        if (localField && setField)
            setField(localField);
    }, [localField, setField])

    //  Update local fields from props
    useEffect(() => {
        if (field && setLocalField)
            setLocalField(field);
    }, [field, setLocalField]);

    return (
        <TableRow>
            {
                readonly &&
                <>
                    <TableCell>
                        <Text>{localField?.name}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{localField?.type}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{localField?.required ? 'yes' : 'no'}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{localField?.length}</Text>
                    </TableCell>
                    <TableCell>
                        <Text>{localField?.pattern}</Text>
                    </TableCell>
                    <TableCell>
                        <Button onClick={() => props.setEditingFieldName(field.name)}>
                            <MdEdit />
                        </Button>
                        <Button onClick={() => props.delete(localField.name)}>
                            <MdDelete />
                        </Button>
                    </TableCell>
                </>
            }
            {
                !readonly &&
                <>
                    <TableCell>
                        <TextField
                            name='name'
                            defaultValue={localField?.name}
                            onChange={handleChange}
                            variation='quiet'
                            required
                            disabled={props.op === 'edit'}
                            labelHidden
                            style={{ width: '12rem' }} />
                    </TableCell>
                    <TableCell>
                        <SelectField
                            name='type'
                            defaultValue={localField?.type}
                            onChange={handleChange}
                            placeholder=' '
                            variation='quiet'
                            required
                            labelHidden
                            style={{ width: '8rem' }}>
                            <option value="Boolean">Boolean</option>
                            <option value="Date">Date</option>
                            <option value="Number">Number</option>
                            <option value="Choice">Choice</option>
                            <option value="String">String</option>
                            <option value="Time">Time</option>
                        </SelectField>
                    </TableCell>
                    <TableCell>
                        <SelectField
                            name='required'
                            defaultValue={localField?.required}
                            onChange={handleChange}
                            placeholder=' '
                            variation='quiet'
                            required
                            labelHidden
                            style={{ width: '8rem' }}>
                            <option>true</option>
                            <option>false</option>
                        </SelectField>
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='length'
                            defaultValue={localField?.length}
                            onChange={handleChange}
                            type='number'
                            variation='quiet'
                            labelHidden
                            style={{ width: '4rem' }} alt='length' />
                    </TableCell>
                    <TableCell>
                        <TextField
                            name='pattern'
                            defaultValue={localField?.pattern}
                            onChange={handleChange}
                            type='string'
                            variation='quiet'
                            labelHidden
                            style={{ width: '4rem' }} alt='length' />
                    </TableCell>
                    <TableCell>
                        <Button type="submit">
                            <MdCheck />
                        </Button>
                        <Button onClick={() => {
                            if (props.setEditingFieldName)
                                props.setEditingFieldName(null);
                            else if (props.cancel)
                                props.cancel()
                        }}>
                            <MdCancel />
                        </Button>
                    </TableCell>
                </>
            }
        </TableRow>
    )
}

export default FieldRow;
