import { Observable, Subscription } from 'rxjs';
import React, { useEffect, useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';

import ActivityTypesList from '../components/activitytypes/list';
import EditActivityType, { Op } from '../components/activitytypes/edit';

import { IGQLClient } from '../client/gqlts';
import * as codegenapi from '../graphql/API';

interface IProps {
    gqlClient: IGQLClient,
    siteid: string | null,
    studyid: string,
}

const ActivityTypes = (props: IProps): JSX.Element => {

    const [activityTypes, setActivityTypes] = useState<codegenapi.ActivityType[]>();

    const [editingActivityType, setEditingActivityType] = useState<codegenapi.ActivityType>();
    const [newActivityType, setNewActivityType] = useState<codegenapi.ActivityType>();

    useEffect(() => {

        async function refreshActivityTypes(studyid: string) {
            props.gqlClient.gqlListActivityTypes(studyid)
                .then(response => {
                    setActivityTypes(response.listActivityTypes as codegenapi.ActivityType[]);
                }).catch(response => {
                    console.error(response.errors);
                });
        }

        let activityTypesSubscription: Subscription;

        if (props.studyid) {

            refreshActivityTypes(props.studyid);

            props.gqlClient.gqlOnActivityTypesUpdatesSubscription(props.studyid)
                .then(subscription => {
                    activityTypesSubscription = (subscription as Observable<codegenapi.OnActivityTypesUpdatesSubscription>).subscribe({
                        next: () => {
                            //  The data returned by the subscrition isn't complete
                            refreshActivityTypes(props.studyid);
                        },
                        error: error => console.warn(error)
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }

        return () => {
            activityTypesSubscription?.unsubscribe();
        }

    }, [props.gqlClient, props.studyid]);

    function addActivityType(studyid: string, activityType: codegenapi.ActivityType) {
        props.gqlClient.gqlAddActivityType(studyid, activityType.description)
            .then(_response => {
                cancelEdit();
            }).catch(response => {
                console.error(response.errors);
            });
    }

    function updateActivityType(studyid: string, activitytypeid: string, activityType: codegenapi.ActivityType) {
        props.gqlClient.gqlUpdateActivityType(studyid, activitytypeid, activityType.description)
            .then(_response => {
                cancelEdit();
            }).catch(response => {
                console.error(response.errors);
            });
    }

    function cancelEdit() {
        setNewActivityType(undefined);
        setEditingActivityType(undefined);
    }

    return (
        <Flex className="flex-row">
            <ActivityTypesList
                activityTypes={activityTypes}
                editingActivityType={editingActivityType}
                gqlClient={props.gqlClient}
                setActivityTypes={setActivityTypes}
                setEditingActivityType={setEditingActivityType}
                setNewActivityType={setNewActivityType}
                siteid={props.siteid}
                studyid={props.studyid}
            />
            {
                editingActivityType &&
                <EditActivityType
                    activityType={editingActivityType}
                    newActivityType={null}
                    cancelEdit={cancelEdit}
                    gqlClient={props.gqlClient}
                    op={Op.UPDATE}
                    siteid={props.siteid}
                    studyid={props.studyid}
                    updateActivityType={updateActivityType}
                />
            }
            {
                newActivityType &&
                <EditActivityType
                    activityType={newActivityType}
                    cancelEdit={cancelEdit}
                    gqlClient={props.gqlClient}
                    newActivityType={addActivityType}
                    op={Op.NEW}
                    siteid={props.siteid}
                    studyid={props.studyid}
                    updateActivityType={null}
                />
            }
        </Flex>
    )
}

export default ActivityTypes;
