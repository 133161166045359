import React from 'react';
import { useParams } from 'react-router-dom'
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { MdEdit, MdDelete } from 'react-icons/md';

import * as codegenapi from '../../graphql/API';
import { IGQLClient } from '../../client/gqlts';

interface IProps {
    activityTypes: codegenapi.ActivityType[] | undefined,
    editingActivityType: codegenapi.ActivityType | undefined,
    gqlClient: IGQLClient,
    setActivityTypes: React.Dispatch<React.SetStateAction<codegenapi.ActivityType[] | undefined>>;
    setEditingActivityType: React.Dispatch<React.SetStateAction<codegenapi.ActivityType | undefined>>;
    setNewActivityType: React.Dispatch<React.SetStateAction<codegenapi.ActivityType | undefined>>;
    siteid: string | null,
    studyid: string,
}

const ActivityTypesList = (props: IProps): JSX.Element => {

    const { activitytypeid } = useParams();

    return (
        <Flex>
            <h1>Activity types</h1>
            <Loader variation="linear" style={{ display: props.activityTypes && 'none' }} />
            {
                props.activityTypes &&
                <Flex>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Activity type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.activityTypes.length > 0 &&
                                props.activityTypes
                                    .sort((a, b) => a.description.localeCompare(b.description))
                                    .map((activityType) => (
                                        <TableRow key={activityType.activitytypeid} style={{ backgroundColor: activityType.activitytypeid === activitytypeid ? 'lightcyan' : 'white' }}>
                                            <TableCell>
                                                {activityType.description}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => props.setEditingActivityType(activityType)}>
                                                    <MdEdit />
                                                </Button>
                                                <Button onClick={() => (props.studyid && props.gqlClient.gqlRemoveActivityType(props.studyid, activityType.activitytypeid))}>
                                                    <MdDelete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                            {
                                props.activityTypes.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={2}><i>No activity types yet!</i></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Flex>
                        <Button variation="primary"
                            onClick={() => props.setNewActivityType({} as codegenapi.ActivityType)}
                        >
                            Add activity type
                        </Button>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

export default ActivityTypesList;
