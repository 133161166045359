/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addDevice = /* GraphQL */ `
  mutation AddDevice($siteid: ID!, $deviceid: String!) {
    addDevice(siteid: $siteid, deviceid: $deviceid) {
      siteid
      deviceid
      hw_id
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($siteid: ID!, $deviceid: String!) {
    updateDevice(siteid: $siteid, deviceid: $deviceid) {
      siteid
      deviceid
      hw_id
    }
  }
`;
export const removeDevice = /* GraphQL */ `
  mutation RemoveDevice($siteid: ID!, $deviceid: String!) {
    removeDevice(siteid: $siteid, deviceid: $deviceid) {
      siteid
      deviceid
      hw_id
    }
  }
`;
export const addPatient = /* GraphQL */ `
  mutation AddPatient($siteid: ID!, $name: String!) {
    addPatient(siteid: $siteid, name: $name) {
      siteid
      patientid
      name
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($siteid: ID!, $patientid: String!, $name: String!) {
    updatePatient(siteid: $siteid, patientid: $patientid, name: $name) {
      siteid
      patientid
      name
    }
  }
`;
export const removePatient = /* GraphQL */ `
  mutation RemovePatient($siteid: ID!, $patientid: String!) {
    removePatient(siteid: $siteid, patientid: $patientid) {
      siteid
      patientid
      name
    }
  }
`;
export const addSite = /* GraphQL */ `
  mutation AddSite($siteid: ID!, $name: String!) {
    addSite(siteid: $siteid, name: $name) {
      siteid
      name
      devices {
        siteid
        deviceid
        hw_id
      }
      patients {
        siteid
        patientid
        name
      }
      studies {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite($siteid: ID!, $name: String!) {
    updateSite(siteid: $siteid, name: $name) {
      siteid
      name
      devices {
        siteid
        deviceid
        hw_id
      }
      patients {
        siteid
        patientid
        name
      }
      studies {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const removeSite = /* GraphQL */ `
  mutation RemoveSite($siteid: ID!) {
    removeSite(siteid: $siteid) {
      siteid
      name
      devices {
        siteid
        deviceid
        hw_id
      }
      patients {
        siteid
        patientid
        name
      }
      studies {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const addStudy = /* GraphQL */ `
  mutation AddStudy($siteid: ID!, $name: String!) {
    addStudy(siteid: $siteid, name: $name) {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      formtypes {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        forms {
          subjectid
          formtypeid
        }
        activities {
          activitytypeid
          subjectid
          comment
          startTime
          stopTime
        }
        devices {
          deviceid
          position
        }
        snapshot
      }
    }
  }
`;
export const updateStudy = /* GraphQL */ `
  mutation UpdateStudy(
    $siteid: ID!
    $studyid: ID!
    $name: String
    $status: String
  ) {
    updateStudy(
      siteid: $siteid
      studyid: $studyid
      name: $name
      status: $status
    ) {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      formtypes {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        forms {
          subjectid
          formtypeid
        }
        activities {
          activitytypeid
          subjectid
          comment
          startTime
          stopTime
        }
        devices {
          deviceid
          position
        }
        snapshot
      }
    }
  }
`;
export const removeStudy = /* GraphQL */ `
  mutation RemoveStudy($siteid: ID!, $studyid: ID!) {
    removeStudy(siteid: $siteid, studyid: $studyid) {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      formtypes {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        forms {
          subjectid
          formtypeid
        }
        activities {
          activitytypeid
          subjectid
          comment
          startTime
          stopTime
        }
        devices {
          deviceid
          position
        }
        snapshot
      }
    }
  }
`;
export const addSubject = /* GraphQL */ `
  mutation AddSubject($input: SubjectInput!) {
    addSubject(input: $input) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const addSubjectV2 = /* GraphQL */ `
  mutation AddSubjectV2($input: SubjectInputV2!) {
    addSubjectV2(input: $input) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const updateSubject = /* GraphQL */ `
  mutation UpdateSubject($input: SubjectUpdateInput!) {
    updateSubject(input: $input) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const removeSubject = /* GraphQL */ `
  mutation RemoveSubject($studyid: ID!, $subjectid: ID!) {
    removeSubject(studyid: $studyid, subjectid: $subjectid) {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        formtype {
          studyid
          formtypeid
          identifier
        }
        fields {
          key
          value
        }
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        activitytype {
          activitytypeid
          description
          studyid
        }
        referenceData {
          referencedataid
          activitytypeid
          siteid
          subjectid
          studyid
          fields
          filename
          persistedObjectName
          objectUrl
          status
        }
      }
      devices {
        deviceid
        position
      }
      snapshot
    }
  }
`;
export const addFormType = /* GraphQL */ `
  mutation AddFormType($input: FormTypeInput!) {
    addFormType(input: $input) {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
      }
    }
  }
`;
export const updateFormType = /* GraphQL */ `
  mutation UpdateFormType($formtypeid: ID!, $input: FormTypeInput) {
    updateFormType(formtypeid: $formtypeid, input: $input) {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
      }
    }
  }
`;
export const removeFormType = /* GraphQL */ `
  mutation RemoveFormType($studyid: ID!, $formtypeid: ID!) {
    removeFormType(studyid: $studyid, formtypeid: $formtypeid) {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
      }
    }
  }
`;
export const addForm = /* GraphQL */ `
  mutation AddForm($input: FormInput!) {
    addForm(input: $input) {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      fields {
        key
        value
      }
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm($input: FormInput!) {
    updateForm(input: $input) {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      fields {
        key
        value
      }
    }
  }
`;
export const removeForm = /* GraphQL */ `
  mutation RemoveForm($subjectid: ID!, $formtypeid: ID!) {
    removeForm(subjectid: $subjectid, formtypeid: $formtypeid) {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        fields {
          name
          type
          required
          length
          pattern
        }
      }
      fields {
        key
        value
      }
    }
  }
`;
export const addActivity = /* GraphQL */ `
  mutation AddActivity($input: ActivityInput!) {
    addActivity(input: $input) {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
      }
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity($input: ActivityInput!) {
    updateActivity(input: $input) {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
      }
    }
  }
`;
export const removeActivity = /* GraphQL */ `
  mutation RemoveActivity($subjectid: ID!, $activitytypeid: ID!) {
    removeActivity(subjectid: $subjectid, activitytypeid: $activitytypeid) {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        study {
          siteid
          studyid
          status
          name
          identifier
        }
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
      }
    }
  }
`;
export const addActivityType = /* GraphQL */ `
  mutation AddActivityType($input: ActivityTypeInput!) {
    addActivityType(input: $input) {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const updateActivityType = /* GraphQL */ `
  mutation UpdateActivityType(
    $activitytypeid: ID!
    $input: ActivityTypeInput!
  ) {
    updateActivityType(activitytypeid: $activitytypeid, input: $input) {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const removeActivityType = /* GraphQL */ `
  mutation RemoveActivityType($studyid: ID!, $activitytypeid: ID!) {
    removeActivityType(studyid: $studyid, activitytypeid: $activitytypeid) {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        activitytypes {
          activitytypeid
          description
          studyid
        }
        formtypes {
          studyid
          formtypeid
          identifier
        }
        subjects {
          siteid
          studyid
          subjectid
          identifier
          status
          snapshot
        }
      }
    }
  }
`;
export const addReferenceData = /* GraphQL */ `
  mutation AddReferenceData($subjectid: ID!, $input: ReferenceDataInput!) {
    addReferenceData(subjectid: $subjectid, input: $input) {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
    }
  }
`;
export const removeReferenceData = /* GraphQL */ `
  mutation RemoveReferenceData($subjectid: ID!, $referencedataid: ID!) {
    removeReferenceData(
      subjectid: $subjectid
      referencedataid: $referencedataid
    ) {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
    }
  }
`;
