import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth, Amplify } from 'aws-amplify';
import { Alert, Button } from '@aws-amplify/ui-react';
import { ErrorBoundary } from "react-error-boundary";

Amplify.configure(window.awsconfig);

// function Test() {
//     throw new Error("Testing");
// }

function RenderErrorFallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <Alert
            isDismissible={true}
            onDismiss={resetErrorBoundary}
            variation="error">
            <p>Unhandled exception during render:</p>
            <pre
                style={{ whiteSpace: 'pre-wrap' }}
            >
                {JSON.stringify(error)}
            </pre>
            <Button
                className="submit-button"
                onClick={() => { navigator.clipboard.writeText(error.message) }}
            >
                Copy to clipboard
            </Button>
        </Alert >
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={RenderErrorFallback}>
            {/* <Test /> */}
            <App auth={Auth} />
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
