import { Button, Card, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@aws-amplify/ui-react';
import { MdOutlineAdd } from 'react-icons/md';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import FieldRow from './fieldrow';

import { IGQLClient } from '../../client/gqlts';
import * as codegenapi from '../../graphql/API';

export enum Op {
    ADD,
    UPDATE
}

interface IProps {
    addFormType: null | ((studyid: string, formtype: codegenapi.FormType) => void),
    cancelEdit: () => void,
    editingFormTypeId: string | null,
    formTypes: codegenapi.FormType[],
    gqlClient: IGQLClient
    op: Op,
    siteid: string | null,
    studyid: string,
    updateFormType: null | ((studyid: string, formtypeid: string, formtype: codegenapi.FormType) => void),
}

const FormTypeFieldsList = (props: IProps): JSX.Element => {

    const [localFormType, setLocalFormType] = useState<codegenapi.FormType>();

    //  to handle new field
    const [newField, setNewField] = useState<codegenapi.FormTypeField>();

    //  to handle updating an existing field
    const [updatedField, setUpdatedField] = useState<codegenapi.FormTypeField>();

    //  selects which field is in edit mode
    const [editingFieldName, setEditingFieldName] = useState(null);

    useEffect(() => {
        if (props.editingFormTypeId)
            setLocalFormType(props.formTypes.find(formType => formType.formtypeid === props.editingFormTypeId));
        else
            setLocalFormType({ identifier: '' } as codegenapi.FormType);
    }, [props.editingFormTypeId, props.formTypes]);

    function saveNewField() {

        if (localFormType && newField) {

            const updatedFormType = localFormType;

            const index = updatedFormType.fields?.findIndex(field => field.name === newField.name);
            if (index && index > -1) {
                console.error('Duplicated field');
                return;
            }
            else
                updatedFormType.fields ? updatedFormType.fields.push(newField) : updatedFormType.fields = [newField];

            setLocalFormType(updatedFormType);
            setNewField(undefined);
        }
    }

    function saveUpdatedField() {

        if (localFormType && updatedField) {

            const updatedFormType = localFormType;
            const index = updatedFormType.fields?.findIndex(field => field.name === updatedField.name);
            if (updatedFormType.fields && index !== undefined && index > -1)
                updatedFormType.fields[index] = updatedField;
            else {
                console.error('Cannot updated non-existing field');
                return;
            }

            setLocalFormType(updatedFormType);
            setUpdatedField(undefined);
            setEditingFieldName(null);
        }
    }

    function deleteField(name: string) {
        setLocalFormType(
            (prevState) =>
            (
                prevState ? {
                    ...prevState,
                    fields: prevState.fields?.filter(field => field.name !== name)
                } : undefined
            )
        );
    }

    //  only triggered when the form is valid
    function postValidation(e: FormEvent<HTMLDivElement>) {

        //  prevent built-in submit but keep form validation
        e.preventDefault();

        //  validation successful on update
        if (updatedField) {
            saveUpdatedField();
        }

        //  validation successful on new field
        if (newField) {
            saveNewField();
        }
    }

    function addFormType() {
        if (localFormType && props.addFormType)
            props.addFormType(props.studyid, localFormType);
    }

    function updateFormType() {
        if (localFormType && props.editingFormTypeId && props.updateFormType)
            props.updateFormType(props.studyid, props.editingFormTypeId, localFormType);
    }

    function cancelNewField() {
        setNewField(undefined);
    }

    function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
        setLocalFormType(prevState => {
            if (prevState)
                return { ...prevState, identifier: e.target.value }
            else
                return { identifier: e.target.value } as codegenapi.FormType
        });
        e.preventDefault();
    }

    return (
        <Flex as="form" onSubmit={postValidation}>
            {
                props.op === Op.ADD &&
                <h1>New form type</h1>
            }
            {
                props.op === Op.UPDATE &&
                <h1>Edit form type</h1>
            }
            <Loader variation="linear" style={{ display: localFormType && 'none' }} />
            {
                localFormType &&
                <Flex>
                    <Card style={{ width: '100%' }}>
                        <TextField
                            label="Identifier"
                            onChange={handleNameChange}
                            value={localFormType?.identifier} />
                    </Card>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Name</TableCell>
                                <TableCell as="th">Type</TableCell>
                                <TableCell as="th">Required</TableCell>
                                <TableCell as="th">Length</TableCell>
                                <TableCell as="th">Pattern</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                localFormType.fields && localFormType.fields.length > 0 && localFormType.fields.map((field) => (
                                    <FieldRow
                                        key={field.name}
                                        op='edit'
                                        field={field}
                                        setField={setUpdatedField}
                                        editingFieldName={editingFieldName}
                                        setEditingFieldName={setEditingFieldName}
                                        delete={deleteField} />
                                ))
                            }
                            {
                                (!localFormType.fields || localFormType.fields.length === 0) && !newField &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={5}><i>No fields!</i></TableCell>
                                </TableRow>
                            }
                            {
                                newField &&
                                <FieldRow
                                    key='newField'
                                    op='new'
                                    field={newField}
                                    setField={setNewField}
                                    cancel={cancelNewField}
                                />
                            }
                            {
                                !newField && !editingFieldName &&
                                <TableRow key='new'>
                                    <TableCell colSpan={6}>
                                        <Button variation='primary' onClick={() => { setNewField({} as codegenapi.FormTypeField) }}>
                                            <MdOutlineAdd />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Flex className="flex-row-fill">
                        {
                            props.op === Op.UPDATE &&
                            <Button
                                disabled={!(!newField && !editingFieldName)}
                                onClick={() => updateFormType()}
                                style={{
                                    flexGrow: 2
                                }}
                                variation="primary"
                            >
                                Update
                            </Button>
                        }
                        {
                            props.op === Op.ADD &&
                            <Button
                                disabled={!(!newField && !editingFieldName)}
                                onClick={() => addFormType()}
                                style={{
                                    flexGrow: 2
                                }}
                                variation="primary"
                            >
                                Add
                            </Button>
                        }
                        <Button
                            onClick={() => props.cancelEdit()}
                            style={{
                                flexGrow: 1
                            }}
                            variation="primary"
                        >
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            }
        </Flex >
    )
}

export default FormTypeFieldsList;
