import { Button, Card, TextField } from '@aws-amplify/ui-react';
import React, { useState } from 'react';

function VerifyEmail(props) {

    //  anti-pattern, but we don't want to update the props until the API call is OK
    const [state, setState] = useState({
        sub: props.userinfo.attributes.sub,
        email: props.userinfo.attributes.email,
        email_verified: props.userinfo.attributes.email_verified,
        code: '',
        isSubmitting: false,
        isResending: false,
    })

    function handleChange(e) {
        setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
        e.preventDefault()
    }

    function onSubmitCodeClick() {
        if (state.code !== '') {
            setState(prevState => ({ ...prevState, isSubmitting: true }));
            props.auth.verifyUserAttributeSubmit(props.user, 'email', state.code)
                .then(() => {
                    props.auth.currentUserInfo().then((userinfo) => {
                        //  update props
                        props.changeUserinfo(userinfo);
                    });
                })
                .finally(() => {
                    setState(prevState => ({ ...prevState, isSubmitting: false }));
                });
        }
    }

    function onResendEmailVerificationCodeClick() {
        setState(prevState => ({ ...prevState, isResending: true }));
        props.auth.verifyUserAttribute(props.user, 'email').finally(() => {
            setState(prevState => ({ ...prevState, isResending: false }));
        })
    }

    return (
        <Card>
            <TextField label='Email address' name='email' value={state.email} onChange={handleChange} isDisabled={true} />
            <TextField label='Verification code' name='code' onChange={handleChange} />
            <Button variation="primary" className="submit-button" isLoading={state.isSubmitting} loadingText='Submitting..' onClick={onSubmitCodeClick}>Submit</Button>
            <Button variation="secondary" className="submit-button" isLoading={state.isResending} loadingText='Resending...' onClick={onResendEmailVerificationCodeClick}>Resend</Button>
        </Card>
    )
}

export default VerifyEmail;
